import Box from "@mui/material/Box";
import { CircularProgress } from "@smartinspection/ameta-frontend-components";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";
import GridLayout from "@smartinspection/ameta-frontend-components/layout/GridLayout";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";
import { useState, useEffect } from "react";

import TopView from "components/TopView";
import LineDetailView from "components/lineDetails/LineDetailView";

import useQueryParams, {
  insertUrlParam,
} from "@smartinspection/ameta-frontend-components/utils/useQueryParams";
import { useQueryDevicesByHashkeyArray } from "services/trafficCounterQueryHooks";

export default function LineDetails() {
  const [deviceId, setDeviceId] = useState(
    "2b5990bd-1949-454d-9372-c1b891522cb7"
  );
  const [lineId, setLineId] = useState("4fddb6e8-2640-4b78-beac-0d0f060306da");
  const [direction, setDirection] = useState("in");

  const deviceIdQP = useQueryParams("deviceId") as string;
  const lineIdQP = useQueryParams("lineId") as string;
  const directionQP = useQueryParams("direction") as string;

  useEffect(() => {
    if (deviceIdQP && deviceIdQP !== deviceId) {
      setDeviceId(deviceIdQP);
    }
    if (lineIdQP && lineIdQP !== lineId) {
      setLineId(lineIdQP);
    }
    if (directionQP && directionQP !== direction) {
      setDirection(directionQP);
    }
  }, [deviceId, lineId, direction, deviceIdQP, lineIdQP, directionQP]);

  const hash = "u4mdv"; // TODO create an api that gets all relevant device info instead of relying on a constant hash
  const devicesQuery = useQueryDevicesByHashkeyArray([hash]);
  const devicesData = devicesQuery.data;
  const devices = devicesData && Object.values(devicesData);

  const optionList: {
    [key: string]: string;
  } = {};

  devices &&
    devices.forEach((device) => {
      device.countingLines?.forEach((line) => {
        if (line.from) {
          optionList[device.id + "|" + line.lineId + "|in"] =
            device.location + " fra " + line.lineName.split("-")[0];
        }
        if (line.to) {
          optionList[device.id + "|" + line.lineId + "|out"] =
            device.location + " mot " + line.lineName.split("-")[0];
        }
      });
    });

  if (devicesQuery.isLoading) {
    return (
      <CenterContainer>
        <CircularProgress size={150} />
      </CenterContainer>
    );
  }

  if (!devices) {
    return (
      <CenterContainer>
        <Text>Ingen tellelinjer funnet</Text>
      </CenterContainer>
    );
  }

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TopView
        title="Trafikktelling for"
        headerContent={
          <Box sx={{ px: 2 }}>
            <OptionsPicker
              label=""
              value={deviceId + "|" + lineId + "|" + direction}
              optionsList={optionList}
              onChange={(value) => {
                const newDeviceId = value.split("|")[0];
                const newLineId = value.split("|")[1];
                const newDirection = value.split("|")[2];

                setDeviceId(newDeviceId);
                setLineId(newLineId);
                setDirection(newDirection);
                insertUrlParam("deviceId", newDeviceId);
                insertUrlParam("lineId", newLineId);
                insertUrlParam("direction", newDirection);
              }}
            />
          </Box>
        }
      />
      <GridLayout
        layout={[
          {
            i: "1",
            h: 30,
            w: 12,
            x: 0,
            y: 0,
          },
        ]}
      >
        <LineDetailView
          key="1"
          lineIdReference={{
            deviceId: deviceId,
            lineId: lineId,
            direction: direction,
          }}
        />
      </GridLayout>
    </Box>
  );
}

import { Auth } from "aws-amplify";
import { QueryClient } from "react-query";

import {
  TrafficCountingApi,
  Configuration as TrafficCounterConfiguration,
} from "services/trafficCounterApi";

export const queryClient = new QueryClient();

export const authorizedTrafficCounterApi: () => Promise<TrafficCountingApi> =
  async () => {
    const currentUserSession = await Auth.currentSession();
    const idToken = currentUserSession.getIdToken().getJwtToken();
    const apiConfig = new TrafficCounterConfiguration({
      apiKey: idToken,
      basePath: process.env.REACT_APP_API_ENDPOINT + "/traffic-counting",
    });
    return new TrafficCountingApi(apiConfig);
  };

import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Box from "@mui/material/Box";
import Tooltip from "@smartinspection/ameta-frontend-components/display/Tooltip";
import React, { useState } from "react";
import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";

export default function Helper({
  children,
  placement,
}: {
  children: React.ReactElement;
  placement:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
}) {
  const theme = useTheme();

  const [focus, setFocus] = useState(false);

  return (
    <Tooltip tooltipContent={children} placement={placement}>
      <Box
        onMouseEnter={() => {
          setFocus(true);
        }}
        onMouseLeave={() => {
          setFocus(false);
        }}
        sx={{
          height: 35,
          aspectRatio: "1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          "&:hover": {
            cursor: "pointer",
          },
          border: "solid",
          borderColor: focus
            ? theme.palette.info.main
            : theme.palette.grey[400],
          borderRadius: "50%",
        }}
      >
        <QuestionMarkIcon
          sx={{
            color: focus ? theme.palette.info.main : theme.palette.grey[500],
          }}
        />
      </Box>
    </Tooltip>
  );
}

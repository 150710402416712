import React from "react";
import Box from "@mui/material/Box";

import Text from "@smartinspection/ameta-frontend-components/display/Text";
import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export default function TrafficFlowStatus({ status }: { status?: string }) {
  const theme = useTheme();

  let color = theme.palette.grey[500];
  let text = "Ingen måling";
  let icon: IconProp = "circle-question";

  switch (status) {
    case "Fast":
      color = theme.palette.success.main;
      text = "Normalt";
      icon = "circle-check";
      break;
    case "Normal":
      color = theme.palette.success.main;
      text = "Normalt";
      icon = "circle-check";
      break;

    case "Slow":
      color = theme.palette.warning.main;
      text = "Sentgående";
      icon = "triangle-exclamation";
      break;

    case "Congestion":
      color = theme.palette.error.main;
      text = "Kø";
      icon = "circle-exclamation";
      break;
    case "congested":
      break;
  }

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          p: 2,
          px: 3,
          display: "flex",
          bgcolor: color,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 4,
        }}
      >
        <Text variant="body1" sx={{ color: "white", px: 2, fontWeight: 600 }}>
          {text}
        </Text>
        <FontAwesomeIcon
          size="2x"
          icon={icon}
          style={{
            color: "white",
          }}
        />
      </Box>
    </Box>
  );
}

import Box from "@mui/material/Box";
import { CircularProgress } from "@smartinspection/ameta-frontend-components";
import Card from "@smartinspection/ameta-frontend-components/display/Card";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";
import GridLayout from "@smartinspection/ameta-frontend-components/layout/GridLayout";
import VerticalSplit from "@smartinspection/ameta-frontend-components/layout/VerticalSplit";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";
import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";
import NoCamera from "assets/img/nocamera.jpg";
import { format } from "date-fns";
import { useEffect, useState } from "react";

import TopView from "components/TopView";
import VideoPlayer from "components/camera/VideoPlayer";

import useQueryParams, {
  insertUrlParam,
} from "@smartinspection/ameta-frontend-components/utils/useQueryParams";
import { DeviceInfo } from "services/trafficCounterApi";
import { useQueryDevicesByHashkeyArray } from "services/trafficCounterQueryHooks";

function InfoElement({
  label,
  value,
  color,
}: {
  label: string;
  value: string | number;
  color?: React.CSSProperties["color"];
}) {
  const theme = useTheme();

  return (
    <Box sx={{ p: 2 }}>
      <Text sx={{ fontWeight: 600 }} variant="h6">
        {label}:
      </Text>
      <Text
        sx={{
          fontWeight: color ? 600 : undefined,
          color: color ? color : theme.palette.text.secondary,
        }}
        variant="body1"
      >
        {value}
      </Text>
    </Box>
  );
}

export default function CameraView() {
  const hash = "u4mdv"; // TODO create an api that gets all relevant device info instead of relying on a constant hash

  const devicesQuery = useQueryDevicesByHashkeyArray([hash]);
  const devicesData = devicesQuery.data;

  const [selectedDevice, setSelectedDevice] = useState<undefined | DeviceInfo>(
    undefined
  );

  const devices = devicesData && Object.values(devicesData);

  const theme = useTheme();

  const [deviceId, setDeviceId] = useState(
    "8640834d-cade-4c61-93e9-43303f1055ea"
  );

  const deviceIdQP = useQueryParams("deviceId") as string;
  useEffect(() => {
    if (deviceIdQP && deviceIdQP !== deviceId) {
      setDeviceId(deviceIdQP);
    }
  }, [deviceId, deviceIdQP]);

  //sets a selected device if a marker is seleced already from the map
  useEffect(() => {
    const deviceMatch =
      devices &&
      deviceId &&
      devices.find((device) => {
        return device.id === deviceId;
      });

    if (deviceMatch) {
      setSelectedDevice(deviceMatch);
    }
  }, [devices, deviceId]);

  if (devicesQuery.isLoading) {
    return (
      <CenterContainer>
        <CircularProgress size={150} />
      </CenterContainer>
    );
  }

  if (!selectedDevice && devices) {
    setSelectedDevice(devices[0]);
  }

  if (!devices || !selectedDevice) {
    return (
      <CenterContainer>
        <Text>Kunne ikke finne noen enheter</Text>
      </CenterContainer>
    );
  }

  const installationDate = format(
    new Date(selectedDevice.installationDateTime),
    "dd-MM-yyyy"
  );

  const optionList: { [key: string]: string } = {};
  devices.forEach((device) => {
    optionList[device.id] = device.deviceName;
  });

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TopView
        title="Kameravisning for"
        headerContent={
          <Box sx={{ px: 2 }}>
            <OptionsPicker
              width={280}
              label=""
              optionsList={optionList}
              value={selectedDevice.id}
              onChange={(id) => {
                setDeviceId(id);
                insertUrlParam("deviceId", id);
              }}
            />
          </Box>
        }
      />
      <GridLayout layout={[{ i: "a", x: 0, y: 0, w: 12, h: 30 }]}>
        <Card key="a" type="drawer" faIcon="video" title="Kameravisning">
          <Box sx={{ p: 2, height: "100%", width: "100%" }}>
            <VerticalSplit
              flexValues={{ left: 4, right: 1 }}
              divider
              left={
                <Box
                  sx={{
                    p: 2,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    direction: "column",
                    justifyContent: "center",
                  }}
                >
                  {selectedDevice.streamId ? (
                    <VideoPlayer streamName={selectedDevice.streamId} />
                  ) : (
                    <img
                      style={{
                        zIndex: 1,
                        height: "100%",
                        width: "100%",
                      }}
                      alt="Camera location background"
                      src={NoCamera}
                    />
                  )}
                </Box>
              }
              right={
                <Box
                  sx={{
                    p: 2,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                  }}
                >
                  <InfoElement
                    label="Lokasjon"
                    value={selectedDevice.location}
                  />
                  <InfoElement
                    label="Installasjonsdato"
                    value={installationDate}
                  />
                  <InfoElement
                    label="Antall Tellelinjer"
                    value={
                      selectedDevice.numberOfCountingDirections
                        ? selectedDevice.numberOfCountingDirections
                        : 0
                    }
                  />
                  <InfoElement
                    label="Antall Køsoner"
                    value={
                      selectedDevice.zonePairsList
                        ? selectedDevice.zonePairsList.length
                        : 0
                    }
                  />
                  <InfoElement
                    label=" Status"
                    color={
                      selectedDevice.deviceStatus === "alarm"
                        ? theme.palette.error.main
                        : selectedDevice.deviceStatus === "warning"
                        ? theme.palette.warning.main
                        : selectedDevice.deviceStatus === "unknown"
                        ? theme.palette.text.secondary
                        : theme.palette.success.main
                    }
                    value={
                      selectedDevice.deviceStatus === "alarm"
                        ? "Alarmerende"
                        : selectedDevice.deviceStatus === "warning"
                        ? "Varslende"
                        : selectedDevice.deviceStatus === "unknown"
                        ? "Ukjent"
                        : "God"
                    }
                  />
                </Box>
              }
            />
          </Box>
        </Card>
      </GridLayout>
    </Box>
  );
}

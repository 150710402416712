import Box from "@mui/material/Box";
import { Button, Dialog } from "@smartinspection/ameta-frontend-components";
import DateTimePicker from "@smartinspection/ameta-frontend-components/pickers/DateTimePicker";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";
import React, { useState } from "react";

enum StepInterval {
  everyStep = "1",
  skip1 = "2",
  skip2 = "3",
  skip3 = "4",
  skip4 = "5",
  skip5 = "6",
}

export default function DateRangePicker({
  open,
  onClose,
  onRangeSet,
}: {
  open: boolean;
  onClose: () => void;
  onRangeSet: (statDate: Date, endDate: Date, stepInterval: number) => void;
}) {
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  const [start, setStart] = useState(yesterday);
  const [end, setEnd] = useState(new Date());

  const optionsList = {
    "Alle punkt": StepInterval.everyStep,
    "Vis annenhver punkt": StepInterval.skip1,
    "Vis hver 3. punkt": StepInterval.skip2,
    "Vis hver 4. punkt": StepInterval.skip3,
    "Vis hver 5. punkt": StepInterval.skip4,
    "Vis hver 6. punkt": StepInterval.skip5,
  };

  const [currentOption, setCurrentOption] = useState("Alle punkt");
  const [stepInterval, setStepInterval] = useState(StepInterval.everyStep);

  return (
    <Dialog
      paperSx={{ height: "40%", minWidth: "50%" }}
      title="Velg datointervall"
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        <DateTimePicker
          width={280}
          label="fra"
          value={start}
          onChange={setStart}
        />
        <DateTimePicker width={280} label="til" value={end} onChange={setEnd} />
        <OptionsPicker
          width={280}
          optionsList={optionsList}
          label="Punkttetthet"
          value={currentOption}
          onChange={(newValue) => {
            setCurrentOption(newValue);
            setStepInterval(optionsList[newValue] as StepInterval);
          }}
        />
        <Box
          sx={{
            p: 4,
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Button type="info" onClick={onClose}>
            Avbryt
          </Button>
          <Button
            type="info"
            onClick={() => {
              console.log("stepInterval", stepInterval);

              onRangeSet(start, end, Number(stepInterval));
            }}
          >
            Sett datointervall
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

// import { ClockPicker, LocalizationProvider } from "@mui/lab";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import { Box } from "@mui/system";
// import Card from "@smartinspection/ameta-frontend-components/display/Card";
// import Table from "@smartinspection/ameta-frontend-components/display/Table";
// import GridLayout from "@smartinspection/ameta-frontend-components/layout/GridLayout";
// import VerticalSplit from "@smartinspection/ameta-frontend-components/layout/VerticalSplit";
// import DatePicker from "@smartinspection/ameta-frontend-components/pickers/DatePicker";
// import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";
// import React, { useState } from "react";

// import {
//   useTrafficEventByDeviceDateLine,
//   useQueryDevicesByHashkeyArray,
// } from "services/trafficCounterQueryHooks";

// function parseISOString(s) {
//   var b = s.split(/\D+/);
//   return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5]));
// }

// const apiConverter = (d: Date) => {
//   const year = d.getUTCFullYear();
//   const month =
//     d.getUTCMonth() < 10 ? "0" + String(d.getUTCMonth() + 1) : d.getUTCMonth();
//   const date =
//     d.getUTCDate() < 10 ? "0" + String(d.getUTCDate()) : d.getUTCDate();
//   const hour =
//     d.getUTCHours() < 10 ? "0" + String(d.getUTCHours()) : d.getUTCHours();

//   return year + "-" + month + "-" + date + "T" + hour;
// };

export default function MapView() {
  // const [date, setDate] = useState<Date | null>(new Date());
  // const [deviceId, setDeviceId] = useState<string | undefined>(undefined);
  // const [lineId, setLineId] = useState<string | undefined>(undefined);
  // const [direction, setDirection] = useState("From");

  // const hash = "u4mdv"; // TODO create an api that gets all relevant device info instead of relying on a constant hash

  // const deviceInfoQuery = useQueryDevicesByHashkeyArray([hash]);
  // const devicesData = deviceInfoQuery.data;

  // const devicesArray = Object.values(devicesData).sort();

  // const deviceIdObject = () => {
  //   let devIdObject: { [key: string]: string } = {};
  //   devicesArray.forEach((marker) => {
  //     devIdObject = { ...devIdObject, [marker.id]: marker.location };
  //   });
  //   return devIdObject;
  // };

  // const lineIdObject = () => {
  //   let lineIdObject: { [key: string]: string } = {};
  //   deviceId &&
  //     deviceInfoQuery?.data?.[deviceId]?.countingLines?.forEach((line) => {
  //       lineIdObject = { ...lineIdObject, [line.lineId]: line.lineName };
  //     });
  //   return lineIdObject;
  // };

  // const trafficEventQuery = useTrafficEventByDeviceDateLine(
  //   deviceId ? deviceId : "",
  //   lineId ? lineId : "",
  //   direction,

  //   date ? apiConverter(date) : ""
  // );

  // const eventData = trafficEventQuery?.data?.objects;

  // const translateTraffic = {
  //   car: "Bil",
  //   bus: "Buss",
  //   truck: "Lastebil",
  //   motorbike: "Motorsykkel",
  // };

  // const tableData = eventData?.map((event) => {
  //   const date = parseISOString(event.sort);

  //   return {
  //     ...event,
  //     dato: date.toLocaleDateString("no-NO", {
  //       month: "long",
  //       day: "numeric",
  //       year: "numeric",
  //     }),
  //     tid: date.toLocaleTimeString("no-NO", {
  //       hour: "2-digit",
  //       minute: "2-digit",
  //     }),
  //     trafikkType: translateTraffic[event.classification],
  //   };
  // });

  // return (
  //   <GridLayout layout={[{ i: "a", x: 0, y: 0, w: 12, h: 30 }]}>
  //     <Card title="Trafikkteller tabell" key="a">
  //       <Box
  //         sx={{
  //           height: "100%",
  //           width: "100%",
  //           display: "flex",
  //           flexDirection: "row",
  //           position: "relative",
  //           padding: 4,
  //         }}
  //       >
  //         <VerticalSplit
  //           flexValues={{ left: 1, right: 3 }}
  //           divider
  //           left={
  //             <Box
  //               sx={{
  //                 display: "flex",
  //                 flexDirection: "column",
  //                 alignItems: "center",
  //               }}
  //             >
  //               <Box sx={{ paddingTop: 3 }}>
  //                 <DatePicker
  //                   label="Dato"
  //                   onChange={setDate}
  //                   value={date}
  //                   width={280}
  //                 />
  //               </Box>
  //               <Box sx={{ paddingTop: 3 }}>
  //                 <LocalizationProvider dateAdapter={AdapterDateFns}>
  //                   <ClockPicker date={date} onChange={setDate} />
  //                 </LocalizationProvider>
  //               </Box>
  //               {deviceIdObject() && (
  //                 <Box sx={{ paddingTop: 3 }}>
  //                   <OptionsPicker
  //                     width={280}
  //                     label={"Kamera"}
  //                     optionsList={deviceIdObject()}
  //                     value={deviceId}
  //                     onChange={setDeviceId}
  //                   />
  //                 </Box>
  //               )}
  //               {deviceId && (
  //                 <Box sx={{ paddingTop: 3 }}>
  //                   <OptionsPicker
  //                     value={lineId}
  //                     label="Vei"
  //                     optionsList={lineIdObject()}
  //                     onChange={setLineId}
  //                     width={280}
  //                   />
  //                 </Box>
  //               )}

  //               {deviceId && (
  //                 <Box sx={{ paddingTop: 3 }}>
  //                   <OptionsPicker
  //                     label="Retning"
  //                     width={280}
  //                     optionsList={{
  //                       From: "Fra",
  //                       Towards: "Mot",
  //                     }}
  //                     value={direction}
  //                     onChange={setDirection}
  //                   />
  //                 </Box>
  //               )}
  //             </Box>
  //           }
  //           right={
  //             <Box
  //               sx={{
  //                 height: "100%",
  //                 width: "100%",
  //                 display: "flex",
  //                 flexDirection: "column",
  //                 justifyContent: "flex-end",
  //                 px: 2,
  //               }}
  //             >
  //               {eventData &&
  //                 eventData.length > 0 &&
  //                 deviceIdObject() &&
  //                 lineIdObject() && (
  //                   <Table
  //                     headers={[
  //                       // uncomment for bugfixing
  //                       // {
  //                       //   fieldName: 'classification',
  //                       //   name: 'classification',
  //                       // },
  //                       // { fieldName: 'date', name: 'date' },
  //                       // { fieldName: 'direction', name: 'direction' },
  //                       // { fieldName: 'id', name: 'id' },
  //                       // { fieldName: 'lineId', name: 'lineId' },
  //                       // { fieldName: 'lineName', name: 'lineName' },
  //                       // { fieldName: 'sort', name: 'sort' },
  //                       { fieldName: "trafikkType", name: "Trafikktype" },
  //                       { fieldName: "tid", name: "time" },
  //                       { fieldName: "dato", name: "Dato" },
  //                     ]}
  //                     data={
  //                       tableData as unknown as {
  //                         [key: string]: string | number;
  //                       }[]
  //                     }
  //                   />
  //                 )}
  //             </Box>
  //           }
  //         />
  //       </Box>
  //     </Card>
  //   </GridLayout>
  // );

  return null;
}

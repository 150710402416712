import Box from "@mui/material/Box";
import GridLayout from "@smartinspection/ameta-frontend-components/layout/GridLayout";
import React from "react";

import TopView from "components/TopView";
import OverviewCard from "components/mainView/OverviewCard";

import { useSelector } from "react-redux";
import { TStore } from "redux/store";
import { DeviceInfo } from "services/trafficCounterApi";
import { useQueryDevicesByHashkeyArray } from "services/trafficCounterQueryHooks";

function MainDash() {
  const globalState = useSelector((state: TStore) => state.global);

  const hash = "u4mdv"; // TODO create an api that gets all relevant device info instead of relying on a constant hash
  const devicesQuery = useQueryDevicesByHashkeyArray([hash]);
  const devices = devicesQuery.data;
  let gartnerløkkaDevice: DeviceInfo | undefined = undefined;
  let gartnerløkkaLineId: string | undefined = undefined;
  let gartnerløkkaZoneId: string | undefined = undefined;

  let vesterveienDevice: DeviceInfo | undefined = undefined;
  let vesterveienLineId: string | undefined = undefined;

  devices &&
    Object.values(devices).forEach((device) => {
      if (device.deviceName === "OP3-NOR21-102-Gartnerløkka") {
        gartnerløkkaDevice = device;
      }
      if (device.deviceName === "OP6-NOR-101-Vesterveien") {
        vesterveienDevice = device;
      }
    });

  gartnerløkkaDevice &&
    (gartnerløkkaDevice as DeviceInfo).countingLines?.forEach((line) => {
      if (line.lineName === "E18") {
        gartnerløkkaLineId = line.lineId;
      }
    });

  // added zonepairid from api response for garternøl
  gartnerløkkaDevice &&
    (gartnerløkkaDevice as DeviceInfo).zonePairsList?.forEach((zone) => {
      if (zone.zonePairName === "Traffikflyt fra E18") {
        gartnerløkkaZoneId = zone.zonePairId;
      }
    });

  vesterveienDevice &&
    (vesterveienDevice as DeviceInfo).countingLines?.forEach((line) => {
      if (line.lineName === "Gartnerløkka") {
        vesterveienLineId = line.lineId;
      }
    });

  let pageContent: React.ReactNode = null;

  if (
    gartnerløkkaDevice &&
    gartnerløkkaLineId &&
    vesterveienDevice &&
    vesterveienLineId
  ) {
    pageContent = (
      <GridLayout
        onLayoutChange={(layout) => {}}
        editable={globalState.layoutEditable}
        layout={[
          {
            i: "1",
            h: 30,
            w: 6,
            x: 0,
            y: 0,
          },
          {
            i: "2",
            h: 30,
            w: 6,
            x: 6,
            y: 0,
          },
        ]}
      >
        {
          <OverviewCard
            key="1"
            cardLabel="Gartnerløkka, fra E18"
            deviceId={(gartnerløkkaDevice as DeviceInfo).id}
            streamId={(gartnerløkkaDevice as DeviceInfo).streamId}
            lineId={gartnerløkkaLineId}
            lineDirection="in"
            zoneId={gartnerløkkaZoneId}
          />
        }
        <OverviewCard
          key="2"
          cardLabel="Vesterveien, fra Gartnerløkka"
          deviceId={(vesterveienDevice as DeviceInfo).id}
          streamId={(vesterveienDevice as DeviceInfo).streamId}
          lineId={vesterveienLineId}
          lineDirection="in"
        />
      </GridLayout>
    );
  }

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TopView title="Dashboard" />
      {pageContent}
    </Box>
  );
}

export default MainDash;

import MarkerCluster from "@smartinspection/ameta-frontend-components/map/MarkerCluster";
import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";
import { FeatureCollection } from "geojson";

import Marker from "components/map/mapMarkers/Marker";

import { DeviceInfo } from "services/trafficCounterApi";

export type MarkerDefinition = {
  deviceId: string;
  markerId: string;
  type: "Camera" | "Line" | "Zone";
  name: string;
  status: string; // "alarm" | "warning" | "ok" | "unknown"
  geojson: FeatureCollection;
  lineDirection?: "in" | "out";
};

export default function DeviceMarkers({
  device,
  onMarkerClick,
}: {
  device: DeviceInfo;
  onMarkerClick: (marker: MarkerDefinition) => void;
}) {
  const theme = useTheme();

  const allDeviceMarkers: MarkerDefinition[] = [];

  const cameraMarker: MarkerDefinition = {
    deviceId: device.id,
    markerId: device.id,
    type: "Camera",
    name: device.deviceName,
    status: device.deviceStatus,
    geojson: device.geojson as FeatureCollection,
  };

  allDeviceMarkers.push(cameraMarker);

  device.countingLines?.forEach((countingLine) => {
    if (countingLine?.to) {
      const lineMarker: MarkerDefinition = {
        deviceId: device.id,
        markerId: countingLine.lineId,
        type: "Line",
        name: "Mot " + countingLine.lineName,
        status: device.deviceStatus,
        geojson: countingLine.to.geojson as FeatureCollection,
        lineDirection: "out",
      };
      allDeviceMarkers.push(lineMarker);
      if (countingLine?.from) {
        const lineMarker: MarkerDefinition = {
          deviceId: device.id,
          markerId: countingLine.lineId,
          type: "Line",
          name: "Fra " + countingLine.lineName,
          status: device.deviceStatus,
          geojson: countingLine.from.geojson as FeatureCollection,
          lineDirection: "in",
        };
        allDeviceMarkers.push(lineMarker);
      }
    }
  });

  device.zonePairsList?.forEach((zone) => {
    const zoneMarker: MarkerDefinition = {
      deviceId: device.id,
      markerId: zone.zonePairId,
      type: "Zone",
      name: device.deviceName,
      status: device.deviceStatus,
      geojson: zone.geojson as FeatureCollection,
    };
    allDeviceMarkers.push(zoneMarker);
  });

  return (
    <MarkerCluster
      clusterColor={
        device.deviceStatus === "alarm"
          ? theme.palette.error.main
          : device.deviceStatus === "warning"
          ? theme.palette.warning.main
          : device.deviceStatus === "unknown"
          ? theme.palette.text.secondary
          : theme.palette.success.main // "status ok"
      }
    >
      {allDeviceMarkers.map((deviceMarker) => {
        return (
          <Marker
            key={deviceMarker.markerId + deviceMarker.lineDirection}
            markerDefinition={deviceMarker}
            onMarkerClick={onMarkerClick}
          />
        );
      })}
    </MarkerCluster>
  );
}

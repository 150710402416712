/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Traffic counting API
 * A rest api for managing the ameta traffic counting system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CountingLine
 */
export interface CountingLine {
    /**
     * 
     * @type {string}
     * @memberof CountingLine
     */
    'lineName': string;
    /**
     * 
     * @type {string}
     * @memberof CountingLine
     */
    'lineId': string;
    /**
     * 
     * @type {CountingLineSection}
     * @memberof CountingLine
     */
    'to'?: CountingLineSection;
    /**
     * 
     * @type {CountingLineSection}
     * @memberof CountingLine
     */
    'from'?: CountingLineSection;
}
/**
 * 
 * @export
 * @interface CountingLineCounterObject
 */
export interface CountingLineCounterObject {
    /**
     * 
     * @type {number}
     * @memberof CountingLineCounterObject
     */
    'car': number;
    /**
     * 
     * @type {number}
     * @memberof CountingLineCounterObject
     */
    'truck': number;
    /**
     * 
     * @type {number}
     * @memberof CountingLineCounterObject
     */
    'bus': number;
    /**
     * 
     * @type {number}
     * @memberof CountingLineCounterObject
     */
    'motorbike': number;
    /**
     * 
     * @type {number}
     * @memberof CountingLineCounterObject
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof CountingLineCounterObject
     */
    'time': number;
}
/**
 * 
 * @export
 * @interface CountingLineCounterObjectList
 */
export interface CountingLineCounterObjectList {
    /**
     * 
     * @type {Array<CountingLineCounterObject>}
     * @memberof CountingLineCounterObjectList
     */
    'objects': Array<CountingLineCounterObject>;
}
/**
 * 
 * @export
 * @interface CountingLineSection
 */
export interface CountingLineSection {
    /**
     * 
     * @type {string}
     * @memberof CountingLineSection
     */
    'referenceId': string;
    /**
     * 
     * @type {string}
     * @memberof CountingLineSection
     */
    'lineStatus': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CountingLineSection
     */
    'laneNumbers': Array<number>;
    /**
     * 
     * @type {FeatureCollection}
     * @memberof CountingLineSection
     */
    'geojson': FeatureCollection;
}
/**
 * 
 * @export
 * @interface DeviceInfo
 */
export interface DeviceInfo {
    /**
     * 
     * @type {string}
     * @memberof DeviceInfo
     */
    'id': string;
    /**
     * 
     * @type {FeatureCollection}
     * @memberof DeviceInfo
     */
    'geojson': FeatureCollection;
    /**
     * 
     * @type {string}
     * @memberof DeviceInfo
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof DeviceInfo
     */
    'installationDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof DeviceInfo
     */
    'deviceName': string;
    /**
     * 
     * @type {string}
     * @memberof DeviceInfo
     */
    'streamId': string;
    /**
     * 
     * @type {Array<CountingLine>}
     * @memberof DeviceInfo
     */
    'countingLines'?: Array<CountingLine>;
    /**
     * 
     * @type {Array<Zone>}
     * @memberof DeviceInfo
     */
    'zonePairsList'?: Array<Zone>;
    /**
     * 
     * @type {string}
     * @memberof DeviceInfo
     */
    'deviceStatus': string;
    /**
     * 
     * @type {number}
     * @memberof DeviceInfo
     */
    'numberOfCountingDirections'?: number;
}
/**
 * 
 * @export
 * @interface EventObject
 */
export interface EventObject {
    /**
     * 
     * @type {string}
     * @memberof EventObject
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EventObject
     */
    'sort': string;
    /**
     * 
     * @type {string}
     * @memberof EventObject
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof EventObject
     */
    'classification': string;
    /**
     * 
     * @type {string}
     * @memberof EventObject
     */
    'lineId': string;
    /**
     * 
     * @type {string}
     * @memberof EventObject
     */
    'lineName': string;
    /**
     * 
     * @type {string}
     * @memberof EventObject
     */
    'direction': string;
    /**
     * 
     * @type {string}
     * @memberof EventObject
     */
    'time': string;
}
/**
 * 
 * @export
 * @interface EventObjectList
 */
export interface EventObjectList {
    /**
     * 
     * @type {Array<EventObject>}
     * @memberof EventObjectList
     */
    'objects': Array<EventObject>;
}
/**
 * GeoJSon Feature
 * @export
 * @interface Feature
 */
export interface Feature {
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    'type': FeatureTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Feature
     */
    'id': number;
    /**
     * 
     * @type {GeometryCollection}
     * @memberof Feature
     */
    'geometry': GeometryCollection;
    /**
     * 
     * @type {object}
     * @memberof Feature
     */
    'properties'?: object;
}

export const FeatureTypeEnum = {
    Feature: 'Feature'
} as const;

export type FeatureTypeEnum = typeof FeatureTypeEnum[keyof typeof FeatureTypeEnum];

/**
 * GeoJSon Feature collection
 * @export
 * @interface FeatureCollection
 */
export interface FeatureCollection {
    /**
     * 
     * @type {string}
     * @memberof FeatureCollection
     */
    'type': FeatureCollectionTypeEnum;
    /**
     * 
     * @type {Array<Feature>}
     * @memberof FeatureCollection
     */
    'features': Array<Feature>;
}

export const FeatureCollectionTypeEnum = {
    FeatureCollection: 'FeatureCollection'
} as const;

export type FeatureCollectionTypeEnum = typeof FeatureCollectionTypeEnum[keyof typeof FeatureCollectionTypeEnum];

/**
 * GeoJSon geometry
 * @export
 * @interface Geometry
 */
export interface Geometry {
    /**
     * the geometry type
     * @type {string}
     * @memberof Geometry
     */
    'type': GeometryTypeEnum;
}

export const GeometryTypeEnum = {
    Point: 'Point',
    LineString: 'LineString',
    Polygon: 'Polygon'
} as const;

export type GeometryTypeEnum = typeof GeometryTypeEnum[keyof typeof GeometryTypeEnum];

/**
 * GeoJSon geometry collection
 * @export
 * @interface GeometryCollection
 */
export interface GeometryCollection {
    /**
     * 
     * @type {string}
     * @memberof GeometryCollection
     */
    'type': GeometryCollectionTypeEnum;
    /**
     * 
     * @type {Array<Geometry>}
     * @memberof GeometryCollection
     */
    'geometries': Array<Geometry>;
}

export const GeometryCollectionTypeEnum = {
    GeometryCollection: 'GeometryCollection'
} as const;

export type GeometryCollectionTypeEnum = typeof GeometryCollectionTypeEnum[keyof typeof GeometryCollectionTypeEnum];

/**
 * GeoJSon geometry
 * @export
 * @interface LineString
 */
export interface LineString {
    /**
     * the geometry type
     * @type {string}
     * @memberof LineString
     */
    'type': LineStringTypeEnum;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof LineString
     */
    'coordinates'?: Array<Array<number>>;
}

export const LineStringTypeEnum = {
    Point: 'Point',
    LineString: 'LineString',
    Polygon: 'Polygon'
} as const;

export type LineStringTypeEnum = typeof LineStringTypeEnum[keyof typeof LineStringTypeEnum];

/**
 * 
 * @export
 * @interface LineStringAllOf
 */
export interface LineStringAllOf {
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof LineStringAllOf
     */
    'coordinates'?: Array<Array<number>>;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
}
/**
 * GeoJSon geometry
 * @export
 * @interface Point
 */
export interface Point {
    /**
     * the geometry type
     * @type {string}
     * @memberof Point
     */
    'type': PointTypeEnum;
    /**
     * Point in 3D space
     * @type {Array<number>}
     * @memberof Point
     */
    'coordinates'?: Array<number>;
}

export const PointTypeEnum = {
    Point: 'Point',
    LineString: 'LineString',
    Polygon: 'Polygon'
} as const;

export type PointTypeEnum = typeof PointTypeEnum[keyof typeof PointTypeEnum];

/**
 * 
 * @export
 * @interface PointAllOf
 */
export interface PointAllOf {
    /**
     * Point in 3D space
     * @type {Array<number>}
     * @memberof PointAllOf
     */
    'coordinates'?: Array<number>;
}
/**
 * GeoJSon geometry
 * @export
 * @interface Polygon
 */
export interface Polygon {
    /**
     * the geometry type
     * @type {string}
     * @memberof Polygon
     */
    'type': PolygonTypeEnum;
    /**
     * 
     * @type {Array<Array<Array<number>>>}
     * @memberof Polygon
     */
    'coordinates'?: Array<Array<Array<number>>>;
}

export const PolygonTypeEnum = {
    Point: 'Point',
    LineString: 'LineString',
    Polygon: 'Polygon'
} as const;

export type PolygonTypeEnum = typeof PolygonTypeEnum[keyof typeof PolygonTypeEnum];

/**
 * 
 * @export
 * @interface PolygonAllOf
 */
export interface PolygonAllOf {
    /**
     * 
     * @type {Array<Array<Array<number>>>}
     * @memberof PolygonAllOf
     */
    'coordinates'?: Array<Array<Array<number>>>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Resolution = {
    Hour: 'hour',
    Minute: 'minute',
    Day: 'day'
} as const;

export type Resolution = typeof Resolution[keyof typeof Resolution];


/**
 * 
 * @export
 * @interface TrafficDevicesResponse
 */
export interface TrafficDevicesResponse {
    /**
     * 
     * @type {Array<DeviceInfo>}
     * @memberof TrafficDevicesResponse
     */
    'objects': Array<DeviceInfo>;
    /**
     * 
     * @type {TrafficDevicesResponseLastEvaluatedKey}
     * @memberof TrafficDevicesResponse
     */
    'lastEvaluatedKey'?: TrafficDevicesResponseLastEvaluatedKey;
}
/**
 * 
 * @export
 * @interface TrafficDevicesResponseLastEvaluatedKey
 */
export interface TrafficDevicesResponseLastEvaluatedKey {
    /**
     * 
     * @type {string}
     * @memberof TrafficDevicesResponseLastEvaluatedKey
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrafficDevicesResponseLastEvaluatedKey
     */
    'sort'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrafficDevicesResponseLastEvaluatedKey
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrafficDevicesResponseLastEvaluatedKey
     */
    'geohash'?: string;
}
/**
 * 
 * @export
 * @interface TrafficFlowObject
 */
export interface TrafficFlowObject {
    /**
     * 
     * @type {number}
     * @memberof TrafficFlowObject
     */
    'avgSpeed': number;
    /**
     * 
     * @type {string}
     * @memberof TrafficFlowObject
     */
    'overAllFlow': string;
    /**
     * 
     * @type {number}
     * @memberof TrafficFlowObject
     */
    'time': number;
}
/**
 * 
 * @export
 * @interface TrafficFlowObjectList
 */
export interface TrafficFlowObjectList {
    /**
     * 
     * @type {Array<TrafficFlowObject>}
     * @memberof TrafficFlowObjectList
     */
    'objects': Array<TrafficFlowObject>;
}
/**
 * 
 * @export
 * @interface Zone
 */
export interface Zone {
    /**
     * 
     * @type {string}
     * @memberof Zone
     */
    'exitZoneName': string;
    /**
     * 
     * @type {string}
     * @memberof Zone
     */
    'entryZoneName': string;
    /**
     * 
     * @type {number}
     * @memberof Zone
     */
    'actualRoadSpeed': number;
    /**
     * 
     * @type {number}
     * @memberof Zone
     */
    'speedForCongestion': number;
    /**
     * 
     * @type {number}
     * @memberof Zone
     */
    'speedForSlow': number;
    /**
     * 
     * @type {number}
     * @memberof Zone
     */
    'speedForFast': number;
    /**
     * 
     * @type {number}
     * @memberof Zone
     */
    'distanceBetweenTwoZone': number;
    /**
     * 
     * @type {string}
     * @memberof Zone
     */
    'referenceId': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Zone
     */
    'laneNumbers': Array<number>;
    /**
     * 
     * @type {FeatureCollection}
     * @memberof Zone
     */
    'geojson': FeatureCollection;
    /**
     * 
     * @type {string}
     * @memberof Zone
     */
    'zonePairName': string;
    /**
     * 
     * @type {string}
     * @memberof Zone
     */
    'zoneStatus': string;
    /**
     * 
     * @type {string}
     * @memberof Zone
     */
    'zonePairId': string;
}

/**
 * CORSApi - axios parameter creator
 * @export
 */
export const CORSApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Enable CORS by returning correct headers
         * @summary CORS support
         * @param {string} id the id of the device
         * @param {string} lineId the line id of the device
         * @param {string} direction the direction of the event
         * @param {string} date the date of the object
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicesIdEventsLineIdOptions: async (id: string, lineId: string, direction: string, date: string, tenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('devicesIdEventsLineIdOptions', 'id', id)
            // verify required parameter 'lineId' is not null or undefined
            assertParamExists('devicesIdEventsLineIdOptions', 'lineId', lineId)
            // verify required parameter 'direction' is not null or undefined
            assertParamExists('devicesIdEventsLineIdOptions', 'direction', direction)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('devicesIdEventsLineIdOptions', 'date', date)
            const localVarPath = `/devices/{id}/events/{lineId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"lineId"}}`, encodeURIComponent(String(lineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers
         * @summary CORS support
         * @param {string} id the id of the device
         * @param {string} zonePairId the zone pair id of the device
         * @param {number} to unix timestamp from where to end getting data
         * @param {number} from unix timestamp from where to start getting data
         * @param {Resolution} resolution resolution shows how deep information of data is needed based on dates
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicesIdFlowOptions: async (id: string, zonePairId: string, to: number, from: number, resolution: Resolution, tenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('devicesIdFlowOptions', 'id', id)
            // verify required parameter 'zonePairId' is not null or undefined
            assertParamExists('devicesIdFlowOptions', 'zonePairId', zonePairId)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('devicesIdFlowOptions', 'to', to)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('devicesIdFlowOptions', 'from', from)
            // verify required parameter 'resolution' is not null or undefined
            assertParamExists('devicesIdFlowOptions', 'resolution', resolution)
            const localVarPath = `/devices/{id}/flow`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (zonePairId !== undefined) {
                localVarQueryParameter['zonePairId'] = zonePairId;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (resolution !== undefined) {
                localVarQueryParameter['resolution'] = resolution;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers
         * @summary CORS support
         * @param {string} id the id of the device
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicesIdOptions: async (id: string, tenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('devicesIdOptions', 'id', id)
            const localVarPath = `/devices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers
         * @summary CORS support
         * @param {string} id the id of the device
         * @param {string} lineId the line id of the device
         * @param {string} direction direction of the traffic flow
         * @param {number} to unix timestamp from where to end getting data
         * @param {number} from unix timestamp from where to start getting data
         * @param {Resolution} resolution resolution shows how deep information of data is needed based on dates
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicesIdPassingsLineIdOptions: async (id: string, lineId: string, direction: string, to: number, from: number, resolution: Resolution, tenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('devicesIdPassingsLineIdOptions', 'id', id)
            // verify required parameter 'lineId' is not null or undefined
            assertParamExists('devicesIdPassingsLineIdOptions', 'lineId', lineId)
            // verify required parameter 'direction' is not null or undefined
            assertParamExists('devicesIdPassingsLineIdOptions', 'direction', direction)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('devicesIdPassingsLineIdOptions', 'to', to)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('devicesIdPassingsLineIdOptions', 'from', from)
            // verify required parameter 'resolution' is not null or undefined
            assertParamExists('devicesIdPassingsLineIdOptions', 'resolution', resolution)
            const localVarPath = `/devices/{id}/passings/{lineId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"lineId"}}`, encodeURIComponent(String(lineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (resolution !== undefined) {
                localVarQueryParameter['resolution'] = resolution;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers
         * @summary CORS support
         * @param {string} hashKey Hash key of generated from coordinates
         * @param {string} [lastEvaluatedKey] last evaluadted key of previous query
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicesQueryOptions: async (hashKey: string, lastEvaluatedKey?: string, tenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hashKey' is not null or undefined
            assertParamExists('devicesQueryOptions', 'hashKey', hashKey)
            const localVarPath = `/devices/query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (hashKey !== undefined) {
                localVarQueryParameter['hashKey'] = hashKey;
            }

            if (lastEvaluatedKey !== undefined) {
                localVarQueryParameter['lastEvaluatedKey'] = lastEvaluatedKey;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CORSApi - functional programming interface
 * @export
 */
export const CORSApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CORSApiAxiosParamCreator(configuration)
    return {
        /**
         * Enable CORS by returning correct headers
         * @summary CORS support
         * @param {string} id the id of the device
         * @param {string} lineId the line id of the device
         * @param {string} direction the direction of the event
         * @param {string} date the date of the object
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async devicesIdEventsLineIdOptions(id: string, lineId: string, direction: string, date: string, tenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.devicesIdEventsLineIdOptions(id, lineId, direction, date, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers
         * @summary CORS support
         * @param {string} id the id of the device
         * @param {string} zonePairId the zone pair id of the device
         * @param {number} to unix timestamp from where to end getting data
         * @param {number} from unix timestamp from where to start getting data
         * @param {Resolution} resolution resolution shows how deep information of data is needed based on dates
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async devicesIdFlowOptions(id: string, zonePairId: string, to: number, from: number, resolution: Resolution, tenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.devicesIdFlowOptions(id, zonePairId, to, from, resolution, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers
         * @summary CORS support
         * @param {string} id the id of the device
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async devicesIdOptions(id: string, tenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.devicesIdOptions(id, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers
         * @summary CORS support
         * @param {string} id the id of the device
         * @param {string} lineId the line id of the device
         * @param {string} direction direction of the traffic flow
         * @param {number} to unix timestamp from where to end getting data
         * @param {number} from unix timestamp from where to start getting data
         * @param {Resolution} resolution resolution shows how deep information of data is needed based on dates
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async devicesIdPassingsLineIdOptions(id: string, lineId: string, direction: string, to: number, from: number, resolution: Resolution, tenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.devicesIdPassingsLineIdOptions(id, lineId, direction, to, from, resolution, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers
         * @summary CORS support
         * @param {string} hashKey Hash key of generated from coordinates
         * @param {string} [lastEvaluatedKey] last evaluadted key of previous query
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async devicesQueryOptions(hashKey: string, lastEvaluatedKey?: string, tenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.devicesQueryOptions(hashKey, lastEvaluatedKey, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CORSApi - factory interface
 * @export
 */
export const CORSApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CORSApiFp(configuration)
    return {
        /**
         * Enable CORS by returning correct headers
         * @summary CORS support
         * @param {string} id the id of the device
         * @param {string} lineId the line id of the device
         * @param {string} direction the direction of the event
         * @param {string} date the date of the object
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicesIdEventsLineIdOptions(id: string, lineId: string, direction: string, date: string, tenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.devicesIdEventsLineIdOptions(id, lineId, direction, date, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers
         * @summary CORS support
         * @param {string} id the id of the device
         * @param {string} zonePairId the zone pair id of the device
         * @param {number} to unix timestamp from where to end getting data
         * @param {number} from unix timestamp from where to start getting data
         * @param {Resolution} resolution resolution shows how deep information of data is needed based on dates
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicesIdFlowOptions(id: string, zonePairId: string, to: number, from: number, resolution: Resolution, tenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.devicesIdFlowOptions(id, zonePairId, to, from, resolution, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers
         * @summary CORS support
         * @param {string} id the id of the device
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicesIdOptions(id: string, tenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.devicesIdOptions(id, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers
         * @summary CORS support
         * @param {string} id the id of the device
         * @param {string} lineId the line id of the device
         * @param {string} direction direction of the traffic flow
         * @param {number} to unix timestamp from where to end getting data
         * @param {number} from unix timestamp from where to start getting data
         * @param {Resolution} resolution resolution shows how deep information of data is needed based on dates
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicesIdPassingsLineIdOptions(id: string, lineId: string, direction: string, to: number, from: number, resolution: Resolution, tenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.devicesIdPassingsLineIdOptions(id, lineId, direction, to, from, resolution, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers
         * @summary CORS support
         * @param {string} hashKey Hash key of generated from coordinates
         * @param {string} [lastEvaluatedKey] last evaluadted key of previous query
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicesQueryOptions(hashKey: string, lastEvaluatedKey?: string, tenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.devicesQueryOptions(hashKey, lastEvaluatedKey, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CORSApi - object-oriented interface
 * @export
 * @class CORSApi
 * @extends {BaseAPI}
 */
export class CORSApi extends BaseAPI {
    /**
     * Enable CORS by returning correct headers
     * @summary CORS support
     * @param {string} id the id of the device
     * @param {string} lineId the line id of the device
     * @param {string} direction the direction of the event
     * @param {string} date the date of the object
     * @param {string} [tenantId] tenantId of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public devicesIdEventsLineIdOptions(id: string, lineId: string, direction: string, date: string, tenantId?: string, options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).devicesIdEventsLineIdOptions(id, lineId, direction, date, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers
     * @summary CORS support
     * @param {string} id the id of the device
     * @param {string} zonePairId the zone pair id of the device
     * @param {number} to unix timestamp from where to end getting data
     * @param {number} from unix timestamp from where to start getting data
     * @param {Resolution} resolution resolution shows how deep information of data is needed based on dates
     * @param {string} [tenantId] tenantId of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public devicesIdFlowOptions(id: string, zonePairId: string, to: number, from: number, resolution: Resolution, tenantId?: string, options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).devicesIdFlowOptions(id, zonePairId, to, from, resolution, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers
     * @summary CORS support
     * @param {string} id the id of the device
     * @param {string} [tenantId] tenantId of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public devicesIdOptions(id: string, tenantId?: string, options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).devicesIdOptions(id, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers
     * @summary CORS support
     * @param {string} id the id of the device
     * @param {string} lineId the line id of the device
     * @param {string} direction direction of the traffic flow
     * @param {number} to unix timestamp from where to end getting data
     * @param {number} from unix timestamp from where to start getting data
     * @param {Resolution} resolution resolution shows how deep information of data is needed based on dates
     * @param {string} [tenantId] tenantId of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public devicesIdPassingsLineIdOptions(id: string, lineId: string, direction: string, to: number, from: number, resolution: Resolution, tenantId?: string, options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).devicesIdPassingsLineIdOptions(id, lineId, direction, to, from, resolution, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers
     * @summary CORS support
     * @param {string} hashKey Hash key of generated from coordinates
     * @param {string} [lastEvaluatedKey] last evaluadted key of previous query
     * @param {string} [tenantId] tenantId of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CORSApi
     */
    public devicesQueryOptions(hashKey: string, lastEvaluatedKey?: string, tenantId?: string, options?: AxiosRequestConfig) {
        return CORSApiFp(this.configuration).devicesQueryOptions(hashKey, lastEvaluatedKey, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrafficCountingApi - axios parameter creator
 * @export
 */
export const TrafficCountingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get traffic passings on counting lines by dates
         * @param {string} id the id of the device
         * @param {string} lineId the line id of the device
         * @param {string} direction direction of the traffic flow
         * @param {number} to unix timestamp from where to end getting data
         * @param {number} from unix timestamp from where to start getting data
         * @param {string} resolution resolution shows how deep information of data is needed based on day, hour or minute
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountLinePassing: async (id: string, lineId: string, direction: string, to: number, from: number, resolution: string, tenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCountLinePassing', 'id', id)
            // verify required parameter 'lineId' is not null or undefined
            assertParamExists('getCountLinePassing', 'lineId', lineId)
            // verify required parameter 'direction' is not null or undefined
            assertParamExists('getCountLinePassing', 'direction', direction)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getCountLinePassing', 'to', to)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getCountLinePassing', 'from', from)
            // verify required parameter 'resolution' is not null or undefined
            assertParamExists('getCountLinePassing', 'resolution', resolution)
            const localVarPath = `/devices/{id}/passings/{lineId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"lineId"}}`, encodeURIComponent(String(lineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ametaAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (resolution !== undefined) {
                localVarQueryParameter['resolution'] = resolution;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get traffic device information from id
         * @param {string} id the id of the device
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrafficDeviceInfoByDeviceId: async (id: string, tenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTrafficDeviceInfoByDeviceId', 'id', id)
            const localVarPath = `/devices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ametaAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all traffic devices by hash key
         * @param {string} hashKey Hash key of generated from coordinates
         * @param {string} [lastEvaluatedKey] last evaluadted key of previous query
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrafficDevices: async (hashKey: string, lastEvaluatedKey?: string, tenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hashKey' is not null or undefined
            assertParamExists('getTrafficDevices', 'hashKey', hashKey)
            const localVarPath = `/devices/query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ametaAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (hashKey !== undefined) {
                localVarQueryParameter['hashKey'] = hashKey;
            }

            if (lastEvaluatedKey !== undefined) {
                localVarQueryParameter['lastEvaluatedKey'] = lastEvaluatedKey;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get traffic events passing on counting line by line id, date, direction
         * @param {string} id the id of the device
         * @param {string} lineId the line id of the device
         * @param {string} direction the direction of the event
         * @param {string} date the date of the object
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrafficEventByDeviceDateLine: async (id: string, lineId: string, direction: string, date: string, tenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTrafficEventByDeviceDateLine', 'id', id)
            // verify required parameter 'lineId' is not null or undefined
            assertParamExists('getTrafficEventByDeviceDateLine', 'lineId', lineId)
            // verify required parameter 'direction' is not null or undefined
            assertParamExists('getTrafficEventByDeviceDateLine', 'direction', direction)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getTrafficEventByDeviceDateLine', 'date', date)
            const localVarPath = `/devices/{id}/events/{lineId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"lineId"}}`, encodeURIComponent(String(lineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ametaAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get traffic flow on device id with other parameters
         * @param {string} id the id of the device
         * @param {string} zonePairId the zone pair id of the device
         * @param {number} to unix timestamp from where to end getting data
         * @param {number} from unix timestamp from where to start getting data
         * @param {Resolution} resolution resolution shows how deep information of data is needed based on dates
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrafficFlowRange: async (id: string, zonePairId: string, to: number, from: number, resolution: Resolution, tenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTrafficFlowRange', 'id', id)
            // verify required parameter 'zonePairId' is not null or undefined
            assertParamExists('getTrafficFlowRange', 'zonePairId', zonePairId)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getTrafficFlowRange', 'to', to)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getTrafficFlowRange', 'from', from)
            // verify required parameter 'resolution' is not null or undefined
            assertParamExists('getTrafficFlowRange', 'resolution', resolution)
            const localVarPath = `/devices/{id}/flow`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ametaAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (zonePairId !== undefined) {
                localVarQueryParameter['zonePairId'] = zonePairId;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (resolution !== undefined) {
                localVarQueryParameter['resolution'] = resolution;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrafficCountingApi - functional programming interface
 * @export
 */
export const TrafficCountingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrafficCountingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get traffic passings on counting lines by dates
         * @param {string} id the id of the device
         * @param {string} lineId the line id of the device
         * @param {string} direction direction of the traffic flow
         * @param {number} to unix timestamp from where to end getting data
         * @param {number} from unix timestamp from where to start getting data
         * @param {string} resolution resolution shows how deep information of data is needed based on day, hour or minute
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountLinePassing(id: string, lineId: string, direction: string, to: number, from: number, resolution: string, tenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountingLineCounterObjectList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountLinePassing(id, lineId, direction, to, from, resolution, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get traffic device information from id
         * @param {string} id the id of the device
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrafficDeviceInfoByDeviceId(id: string, tenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrafficDeviceInfoByDeviceId(id, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all traffic devices by hash key
         * @param {string} hashKey Hash key of generated from coordinates
         * @param {string} [lastEvaluatedKey] last evaluadted key of previous query
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrafficDevices(hashKey: string, lastEvaluatedKey?: string, tenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrafficDevicesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrafficDevices(hashKey, lastEvaluatedKey, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get traffic events passing on counting line by line id, date, direction
         * @param {string} id the id of the device
         * @param {string} lineId the line id of the device
         * @param {string} direction the direction of the event
         * @param {string} date the date of the object
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrafficEventByDeviceDateLine(id: string, lineId: string, direction: string, date: string, tenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventObjectList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrafficEventByDeviceDateLine(id, lineId, direction, date, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get traffic flow on device id with other parameters
         * @param {string} id the id of the device
         * @param {string} zonePairId the zone pair id of the device
         * @param {number} to unix timestamp from where to end getting data
         * @param {number} from unix timestamp from where to start getting data
         * @param {Resolution} resolution resolution shows how deep information of data is needed based on dates
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrafficFlowRange(id: string, zonePairId: string, to: number, from: number, resolution: Resolution, tenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrafficFlowObjectList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrafficFlowRange(id, zonePairId, to, from, resolution, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TrafficCountingApi - factory interface
 * @export
 */
export const TrafficCountingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrafficCountingApiFp(configuration)
    return {
        /**
         * 
         * @summary get traffic passings on counting lines by dates
         * @param {string} id the id of the device
         * @param {string} lineId the line id of the device
         * @param {string} direction direction of the traffic flow
         * @param {number} to unix timestamp from where to end getting data
         * @param {number} from unix timestamp from where to start getting data
         * @param {string} resolution resolution shows how deep information of data is needed based on day, hour or minute
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountLinePassing(id: string, lineId: string, direction: string, to: number, from: number, resolution: string, tenantId?: string, options?: any): AxiosPromise<CountingLineCounterObjectList> {
            return localVarFp.getCountLinePassing(id, lineId, direction, to, from, resolution, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get traffic device information from id
         * @param {string} id the id of the device
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrafficDeviceInfoByDeviceId(id: string, tenantId?: string, options?: any): AxiosPromise<DeviceInfo> {
            return localVarFp.getTrafficDeviceInfoByDeviceId(id, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all traffic devices by hash key
         * @param {string} hashKey Hash key of generated from coordinates
         * @param {string} [lastEvaluatedKey] last evaluadted key of previous query
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrafficDevices(hashKey: string, lastEvaluatedKey?: string, tenantId?: string, options?: any): AxiosPromise<TrafficDevicesResponse> {
            return localVarFp.getTrafficDevices(hashKey, lastEvaluatedKey, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get traffic events passing on counting line by line id, date, direction
         * @param {string} id the id of the device
         * @param {string} lineId the line id of the device
         * @param {string} direction the direction of the event
         * @param {string} date the date of the object
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrafficEventByDeviceDateLine(id: string, lineId: string, direction: string, date: string, tenantId?: string, options?: any): AxiosPromise<EventObjectList> {
            return localVarFp.getTrafficEventByDeviceDateLine(id, lineId, direction, date, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get traffic flow on device id with other parameters
         * @param {string} id the id of the device
         * @param {string} zonePairId the zone pair id of the device
         * @param {number} to unix timestamp from where to end getting data
         * @param {number} from unix timestamp from where to start getting data
         * @param {Resolution} resolution resolution shows how deep information of data is needed based on dates
         * @param {string} [tenantId] tenantId of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrafficFlowRange(id: string, zonePairId: string, to: number, from: number, resolution: Resolution, tenantId?: string, options?: any): AxiosPromise<TrafficFlowObjectList> {
            return localVarFp.getTrafficFlowRange(id, zonePairId, to, from, resolution, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrafficCountingApi - object-oriented interface
 * @export
 * @class TrafficCountingApi
 * @extends {BaseAPI}
 */
export class TrafficCountingApi extends BaseAPI {
    /**
     * 
     * @summary get traffic passings on counting lines by dates
     * @param {string} id the id of the device
     * @param {string} lineId the line id of the device
     * @param {string} direction direction of the traffic flow
     * @param {number} to unix timestamp from where to end getting data
     * @param {number} from unix timestamp from where to start getting data
     * @param {string} resolution resolution shows how deep information of data is needed based on day, hour or minute
     * @param {string} [tenantId] tenantId of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrafficCountingApi
     */
    public getCountLinePassing(id: string, lineId: string, direction: string, to: number, from: number, resolution: string, tenantId?: string, options?: AxiosRequestConfig) {
        return TrafficCountingApiFp(this.configuration).getCountLinePassing(id, lineId, direction, to, from, resolution, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get traffic device information from id
     * @param {string} id the id of the device
     * @param {string} [tenantId] tenantId of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrafficCountingApi
     */
    public getTrafficDeviceInfoByDeviceId(id: string, tenantId?: string, options?: AxiosRequestConfig) {
        return TrafficCountingApiFp(this.configuration).getTrafficDeviceInfoByDeviceId(id, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all traffic devices by hash key
     * @param {string} hashKey Hash key of generated from coordinates
     * @param {string} [lastEvaluatedKey] last evaluadted key of previous query
     * @param {string} [tenantId] tenantId of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrafficCountingApi
     */
    public getTrafficDevices(hashKey: string, lastEvaluatedKey?: string, tenantId?: string, options?: AxiosRequestConfig) {
        return TrafficCountingApiFp(this.configuration).getTrafficDevices(hashKey, lastEvaluatedKey, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get traffic events passing on counting line by line id, date, direction
     * @param {string} id the id of the device
     * @param {string} lineId the line id of the device
     * @param {string} direction the direction of the event
     * @param {string} date the date of the object
     * @param {string} [tenantId] tenantId of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrafficCountingApi
     */
    public getTrafficEventByDeviceDateLine(id: string, lineId: string, direction: string, date: string, tenantId?: string, options?: AxiosRequestConfig) {
        return TrafficCountingApiFp(this.configuration).getTrafficEventByDeviceDateLine(id, lineId, direction, date, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get traffic flow on device id with other parameters
     * @param {string} id the id of the device
     * @param {string} zonePairId the zone pair id of the device
     * @param {number} to unix timestamp from where to end getting data
     * @param {number} from unix timestamp from where to start getting data
     * @param {Resolution} resolution resolution shows how deep information of data is needed based on dates
     * @param {string} [tenantId] tenantId of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrafficCountingApi
     */
    public getTrafficFlowRange(id: string, zonePairId: string, to: number, from: number, resolution: Resolution, tenantId?: string, options?: AxiosRequestConfig) {
        return TrafficCountingApiFp(this.configuration).getTrafficFlowRange(id, zonePairId, to, from, resolution, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



import Box from "@mui/material/Box";
import Button from "@smartinspection/ameta-frontend-components/controls/Button";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";
import NoCamera from "assets/img/nocamera.jpg";
import { format, parseISO } from "date-fns";
import FeatherIcon from "feather-icons-react";
import React from "react";
import { useNavigate } from "react-router-dom";

import VideoPlayer from "components/camera/VideoPlayer";
import { MarkerDefinition } from "components/map/mapMarkers/DeviceMarkers";

import { DeviceInfo } from "services/trafficCounterApi";

export default function CamaerView({
  marker,
  deviceInfo,
}: {
  marker: MarkerDefinition;
  deviceInfo: DeviceInfo;
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const installationDate = format(
    parseISO(deviceInfo.installationDateTime),
    "dd-MM-yyyy"
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box sx={{ flex: 1, position: "relative", width: "100%" }}>
        {deviceInfo.streamId ? (
          <VideoPlayer streamName={deviceInfo.streamId} />
        ) : (
          <img
            style={{
              zIndex: 1,
              height: "100%",
              width: "100%",
            }}
            alt="Camera location background"
            src={NoCamera}
          />
        )}
      </Box>

      <Box sx={{ flex: 2, p: 2 }}>
        <Box
          sx={{
            p: 2,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text sx={{ fontWeight: 600 }} variant="h4">
            {deviceInfo.location}
          </Text>

          <Button
            type="info"
            onClick={() => {
              navigate(`/dashboard/camera?deviceId=${deviceInfo.id}`);
            }}
            endIcon={<FeatherIcon icon="camera" />}
          >
            Gå til detaljert visning
          </Button>
        </Box>

        <Box
          sx={{
            px: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Box sx={{ p: 2, width: "50%" }}>
            <Text sx={{ fontWeight: 600 }} variant="body1">
              Installasjonsdato:
            </Text>
            <Text variant="body1" sx={{ color: theme.palette.text.secondary }}>
              {installationDate}
            </Text>
          </Box>
          <Box sx={{ p: 2, width: "50%" }}>
            <Text sx={{ fontWeight: 600 }} variant="body1">
              Antall Tellelinjer:
            </Text>
            <Text variant="body1" sx={{ color: theme.palette.text.secondary }}>
              {deviceInfo.numberOfCountingDirections
                ? deviceInfo.numberOfCountingDirections
                : 0}
            </Text>
          </Box>
          <Box sx={{ p: 2, width: "50%" }}>
            <Text sx={{ fontWeight: 600 }} variant="body1">
              Antall Køsoner:
            </Text>
            <Text variant="body1" sx={{ color: theme.palette.text.secondary }}>
              {deviceInfo.zonePairsList ? deviceInfo.zonePairsList.length : 0}
            </Text>
          </Box>

          <Box sx={{ p: 2, width: "50%" }}>
            <Text sx={{ fontWeight: 600 }} variant="body1">
              Status:
            </Text>
            <Text
              variant="body1"
              sx={{
                fontWeight: 600,
                color:
                  deviceInfo.deviceStatus === "alarm"
                    ? theme.palette.error.main
                    : deviceInfo.deviceStatus === "warning"
                    ? theme.palette.warning.main
                    : deviceInfo.deviceStatus === "unknown"
                    ? theme.palette.text.secondary
                    : theme.palette.success.main,
              }}
            >
              {deviceInfo.deviceStatus === "alarm"
                ? "Alarmerende"
                : deviceInfo.deviceStatus === "warning"
                ? "Varslende"
                : deviceInfo.deviceStatus === "unknown"
                ? "Ukjent"
                : "God"}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

import DashboardIcon from "@mui/icons-material/Dashboard";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import MapIcon from "@mui/icons-material/Map";
import SettingsIcon from "@mui/icons-material/Settings";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import TableRowsIcon from "@mui/icons-material/TableRows";
import VideocamIcon from "@mui/icons-material/Videocam";
import SINavBar from "@smartinspection/ameta-frontend-components/navigation/NavBar";
import { Auth } from "aws-amplify";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { toggleHideMenus } from "redux/global";
import { TStore } from "redux/store";
import { queryClient } from "services/apiService";

export default function NavBarWrapper() {
  const navigate = useNavigate();
  const globalState = useSelector((state: TStore) => state.global);
  const dispatch = useDispatch();

  const menuItems = [
    {
      title: "Dashboard",
      icon: DashboardIcon,
      path: "/dashboard",
      onClick: () => {
        navigate("/dashboard");
      },
    },
    {
      title: "Kartvisning",
      icon: MapIcon,
      path: "/dashboard/map",
      onClick: () => {
        navigate("/dashboard/map");
      },
    },

    {
      title: "Trafikktelling",
      icon: DirectionsCarIcon,
      path: "/dashboard/linecount",
      onClick: () => navigate("/dashboard/linecount"),
    },
    {
      title: "Trafikkflyt",
      icon: StackedLineChartIcon,
      path: "/dashboard/trafficflow",
      onClick: () => navigate("/dashboard/trafficflow"),
    },

    {
      title: "Kameravisning",
      icon: VideocamIcon,
      path: "/dashboard/camera",
      onClick: () => navigate("/dashboard/camera"),
    },
  ];

  if (process.env.NODE_ENV === "development") {
    menuItems.push({
      title: "Tabell trafikkteller",
      icon: TableRowsIcon,
      path: "/dashboard/table",
      onClick: () => navigate("/dashboard/table"),
    });
  }
  if (process.env.NODE_ENV === "development") {
    menuItems.push({
      title: "Innstillinger",
      icon: SettingsIcon,
      path: "/dashboard/settings",
      onClick: () => {
        navigate("/dashboard/settings");
      },
    });
  }
  return (
    <SINavBar
      hideMenu={globalState.hideMenus}
      onMinimizeClick={() => {
        dispatch(toggleHideMenus());
        setTimeout(() => {
          window.dispatchEvent(new Event("resize"));
        }, 300);
      }}
      avatar={{
        menuItems: [
          {
            label: "Logg ut",
            onClick: () => {
              Auth.signOut().then(() => {
                queryClient.clear();
              });
            },
          },
        ],
      }}
      menuItems={menuItems}
    />
  );
}

import LayersIcon from "@mui/icons-material/Layers";
import MapIcon from "@mui/icons-material/Map";
import WithSideDrawer from "@smartinspection/ameta-frontend-components/layout/WithSideDrawer";
import LayerControl from "@smartinspection/ameta-frontend-components/map/LayerControl";
import Map from "@smartinspection/ameta-frontend-components/map/Map";
import "assets/css/leaflet.awesome-markers/leaflet.awesome-markers.css";
import { TileLayer } from "leaflet";
import "leaflet.awesome-markers";
import geohash from "ngeohash";
import { useCallback, useState } from "react";

import DrawerContent from "components/map/drawer/DrawerContent";
import DeviceMarkers, {
  MarkerDefinition,
} from "components/map/mapMarkers/DeviceMarkers";

import { useDispatch, useSelector } from "react-redux";
import { setHashArray } from "redux/map";
import { TStore } from "redux/store";
import { useQueryDevicesByHashkeyArray } from "services/trafficCounterQueryHooks";

export default function MapView() {
  const [selectedMarker, setselectedMarker] = useState<
    MarkerDefinition | undefined
  >(undefined);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const dispatch = useDispatch();
  const mapState = useSelector((state: TStore) => state.map);
  const handleCoordinates = useCallback(
    (mapRef) => {
      const southWest = mapRef.getBounds()._southWest;
      const northEast = mapRef.getBounds()._northEast;
      let precision = 5;
      let calculatedBBXHashArray = geohash.bboxes(
        southWest.lat,
        southWest.lng,
        northEast.lat,
        northEast.lng,
        precision
      );
      while (calculatedBBXHashArray.length > 5) {
        precision -= 1;
        calculatedBBXHashArray = geohash.bboxes(
          southWest.lat,
          southWest.lng,
          northEast.lat,
          northEast.lng,
          precision
        );
      }
      const updatedHashArray = [...mapState.hashArray];
      calculatedBBXHashArray.forEach((element) => {
        if (updatedHashArray.length === 0) {
          updatedHashArray.push(element);
        }

        if (!updatedHashArray.includes(element)) {
          updatedHashArray.push(element);
        }
      });

      dispatch(setHashArray(updatedHashArray));
    },
    [dispatch, mapState.hashArray]
  );

  const deviceQueries = useQueryDevicesByHashkeyArray(mapState.hashArray);
  const devices = deviceQueries.data && Object.values(deviceQueries.data);

  // statuses = statuses.sort(); // Hack, but works for now. Probably not bulletproof. Used to avoid getting wrong colors if the amount of colors changes from 2 to 3 or vice versa, in some spesific cases.

  return (
    <WithSideDrawer
      placement="left"
      drawerWidth={700}
      background="paper"
      open={drawerOpen}
      onClickAway={() => {
        setDrawerOpen(false);
      }}
      drawerContent={
        selectedMarker ? <DrawerContent marker={selectedMarker} /> : null
      }
    >
      <Map
        whenCreated={handleCoordinates}
        center={mapState.centerPoint}
        zoom={mapState.zoomLevel}
        onZoomEnd={(e) => {
          handleCoordinates(e.target);
        }}
        onDragEnd={(e) => {
          handleCoordinates(e.target);
        }}
      >
        <LayerControl
          controllers={[
            {
              name: "Kartlag",
              stackLayers: false,
              Icon: LayersIcon,
              layers: [
                {
                  name: "Grunnkart",
                  Icon: MapIcon,
                  checked: true,
                  layer: new TileLayer(
                    "https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=norges_grunnkart&zoom={z}&x={x}&y={y}",
                    { zIndex: 1, attribution: "Kartverket" }
                  ),
                },
                {
                  name: "Satelitt",
                  Icon: MapIcon,
                  layer: new TileLayer(
                    "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                    { zIndex: 1, attribution: "Arcgis" }
                  ),
                },
              ],
            },
          ]}
        />
        {devices &&
          devices.map((device) => {
            return (
              <DeviceMarkers
                key={device.id}
                device={device}
                onMarkerClick={(marker) => {
                  setDrawerOpen(true);
                  setselectedMarker(marker);
                }}
              />
            );
          })}
      </Map>
    </WithSideDrawer>
  );
}

import BarChartIcon from "@mui/icons-material/BarChart";
import Box from "@mui/material/Box";
import LineChart from "@smartinspection/ameta-frontend-components/charts/LineChart";
import Button from "@smartinspection/ameta-frontend-components/controls/Button";
import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";
import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";
import { format, parseISO } from "date-fns";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { MarkerDefinition } from "components/map/mapMarkers/DeviceMarkers";

import {
  generateFloatingWindow,
  Step,
} from "@smartinspection/ameta-frontend-components/utils/chartUtils";
import { DeviceInfo, CountingLine } from "services/trafficCounterApi";
import { useQueryCountLinePassingByRange } from "services/trafficCounterQueryHooks";

export default function LineDrawerContent({
  marker,
  deviceInfo,
  lineDirection,
}: {
  marker: MarkerDefinition;
  deviceInfo: DeviceInfo;
  lineDirection: "in" | "out";
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const installationDate = format(
    parseISO(
      deviceInfo.installationDateTime
        ? deviceInfo.installationDateTime
        : "2022-01-26"
    ),
    "dd-MM-yyyy"
  );
  const selectedCoutingLine: CountingLine | undefined =
    deviceInfo.countingLines?.find((el) => {
      return el.lineId === marker.markerId;
    });

  const timeWindow = generateFloatingWindow(Step.Hour, 12, 2);
  const lineCounterQuery = useQueryCountLinePassingByRange(
    deviceInfo.id,
    timeWindow.start,
    timeWindow.end,
    "hour",
    marker.markerId,
    lineDirection
  );
  useEffect(() => {
    return () => {
      lineCounterQuery.remove();
    };
  });

  const lineData =
    lineDirection === "out"
      ? selectedCoutingLine?.to
      : selectedCoutingLine?.from;

  if (!selectedCoutingLine || !lineData) {
    return (
      <CenterContainer>
        <Text>Kunne ikke finne tellelinje</Text>
      </CenterContainer>
    );
  }

  const trafficCounter = lineCounterQuery.data?.objects;

  const multiplyTimeWith1000 = (counter) => {
    return counter.map((event) => {
      return { ...event, time: event.time * 1000 }; // TODO: recharts needs unixtime with ms. Moving average will give a wrong impression if it's not implemented.
    });
  };

  let chartContent: React.ReactNode = null;

  if (lineCounterQuery.isLoading) {
    chartContent = (
      <CenterContainer>
        <CircularProgress size={120} />
      </CenterContainer>
    );
  } else {
    if (trafficCounter && trafficCounter.length > 0) {
      chartContent = (
        <Box sx={{ height: 300, width: "100%" }}>
          <Text variant="h6" sx={{ fontWeight: 600 }}>
            Passeringer siste 12 timer
          </Text>
          <LineChart
            data={multiplyTimeWith1000(trafficCounter) as any}
            yAxisLabel="Passeringer"
            margin={{ left: 15, top: 0, right: 15, bottom: 15 }}
            dataProps={[
              {
                fieldName: "total",
                name: "Passeringer, all trafikk",
                color: theme.palette.secondary.main,
              },
            ]}
            timeWindow={timeWindow}
          />
        </Box>
      );
    } else {
      chartContent = (
        <CenterContainer>
          <Text variant="h5">Ingen data</Text>
        </CenterContainer>
      );
    }
  }

  return (
    <Box
      sx={{ height: "100%", display: "flex", flexDirection: "column", p: 2 }}
    >
      <Box
        sx={{
          p: 2,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text sx={{ fontWeight: 600 }} variant="h4">
          {selectedCoutingLine.lineName}
        </Text>

        <Button
          type="info"
          onClick={() => {
            navigate(
              `/dashboard/linecount?deviceId=${deviceInfo.id}&lineId=${selectedCoutingLine.lineId}&direction=${lineDirection}`
            );
          }}
          endIcon={<BarChartIcon />}
        >
          Gå til detaljert visning
        </Button>
      </Box>

      <Box
        sx={{
          px: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ p: 2, width: "50%" }}>
          <Text sx={{ fontWeight: 600 }} variant="body1">
            Kamera:
          </Text>
          <Text variant="body1" sx={{ color: theme.palette.text.secondary }}>
            {deviceInfo.location}
          </Text>
        </Box>

        <Box sx={{ p: 2, width: "50%" }}>
          <Text sx={{ fontWeight: 600 }} variant="body1">
            Retning:
          </Text>
          <Text variant="body1" sx={{ color: theme.palette.text.secondary }}>
            {lineData.laneNumbers?.[0] && lineData.laneNumbers[0] % 2 === 0
              ? "Mot metreringsretning"
              : "Med metreringsretning"}
          </Text>
        </Box>

        <Box sx={{ p: 2, width: "50%" }}>
          <Text sx={{ fontWeight: 600 }} variant="body1">
            Vegsystemreferanse:
          </Text>
          <Text variant="body1" sx={{ color: theme.palette.text.secondary }}>
            {lineData.referenceId}
          </Text>
        </Box>

        <Box sx={{ p: 2, width: "50%" }}>
          <Text sx={{ fontWeight: 600 }} variant="body1">
            Kjørefeltsnummer:
          </Text>
          <Text variant="body1" sx={{ color: theme.palette.text.secondary }}>
            {lineData.laneNumbers?.map((lane) => String(lane)).join(", ")}
          </Text>
        </Box>
        <Box sx={{ p: 2, width: "50%" }}>
          <Text sx={{ fontWeight: 600 }} variant="body1">
            Installasjonsdato:
          </Text>
          <Text variant="body1" sx={{ color: theme.palette.text.secondary }}>
            {installationDate}
          </Text>
        </Box>

        <Box sx={{ p: 2, width: "50%" }}>
          <Text sx={{ fontWeight: 600 }} variant="body1">
            Status:
          </Text>
          <Text
            variant="body1"
            sx={{
              fontWeight: 600,
              color:
                marker.status === "alarm"
                  ? theme.palette.error.main
                  : marker.status === "warning"
                  ? theme.palette.warning.main
                  : marker.status === "unknown"
                  ? theme.palette.text.secondary
                  : theme.palette.success.main,
            }}
          >
            {marker.status === "alarm"
              ? "Alarmerende"
              : marker.status === "warning"
              ? "Varslende"
              : marker.status === "unknown"
              ? "Ukjent"
              : "God"}
          </Text>
        </Box>
      </Box>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {chartContent}
      </Box>
    </Box>
  );
}

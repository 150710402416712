import { format } from "date-fns";
import { useQuery, useQueries } from "react-query";

import { authorizedTrafficCounterApi } from "services/apiService";
import {
  TrafficCountingApi,
  DeviceInfo,
  TrafficDevicesResponse,
  EventObjectList,
  CountingLineCounterObjectList,
  Resolution,
  TrafficFlowObjectList,
} from "services/trafficCounterApi";
import "services/trafficCounterApi";

// Query for traffic flow graphs

export const useQueryTrafficFlowRange = (
  deviceId: string,
  to: number,
  from: number,
  resolution: Resolution,
  zonePairId: string,
  disabled?: boolean
): {
  data: TrafficFlowObjectList | undefined;
  refetch: () => void;
  isLoading: boolean;
} => {
  const { data, refetch, isLoading } = useQuery(
    [
      "trafficFlow: " + deviceId,
      "from:" + format(from, "yyyy-MM-dd HH:mm"),
      "to: " + format(to, "yyyy-MM-dd HH:mm"),
      "resolution: " + resolution,
      "zonePairId: " + zonePairId,
    ],
    async () => {
      const api = await authorizedTrafficCounterApi();
      return (
        await api.getTrafficFlowRange(
          deviceId,
          zonePairId,
          Math.floor(from / 1000),
          Math.floor(to / 1000),
          resolution
        )
      ).data;
    },
    {
      retry: 1,
      refetchInterval: 60000,
      enabled: !disabled,
      keepPreviousData: true,
    }
  );

  return { data, refetch, isLoading };
};

export const useQueryTrafficDeviceInfoByDeviceId = (
  sensorId: string
): {
  data: DeviceInfo | undefined;
  refetch: () => void;
  isLoading: boolean;
} => {
  const { data, refetch, isLoading } = useQuery(
    ["trafficDeviceInfo " + sensorId, sensorId],
    async () => {
      const api = await authorizedTrafficCounterApi();
      return (await api.getTrafficDeviceInfoByDeviceId(sensorId)).data;
    },
    { retry: 1 }
  );

  return { data, refetch, isLoading };
};

const paginationQueryTrafficCounter = async (
  api: TrafficCountingApi,
  hashKey: string,
  temporaryObjectStore: DeviceInfo[],
  lastEvaluatedKey?: string
) => {
  await api
    .getTrafficDevices(hashKey, lastEvaluatedKey)
    .then(async (response) => {
      response.data.objects &&
        response.data.objects.forEach((obj) => {
          temporaryObjectStore.push(obj);
        });
      if (response.data.lastEvaluatedKey) {
        await paginationQueryTrafficCounter(
          api,
          hashKey,
          temporaryObjectStore,
          JSON.stringify(response.data.lastEvaluatedKey)
        );
      }
    });
};

// Used for map, temporary used for getting all devices elsewhere

export const useQueryDevicesByHashkeyArray = (
  hashKeyArray: string[]
): {
  data: { [key: string]: DeviceInfo } | undefined;
  isLoading: boolean;
} => {
  const queryArray = hashKeyArray.map((element) => {
    return {
      queryKey: ["TrafficDevices", element],
      queryFn: async () => {
        const temporaryObjectStore: DeviceInfo[] = [];
        const api = await authorizedTrafficCounterApi();
        await paginationQueryTrafficCounter(api, element, temporaryObjectStore);
        return { objects: temporaryObjectStore };
      },
      retry: 1,
      refetchInterval: 60000,
    };
  });
  const data: { [key: string]: DeviceInfo } = {};
  const queriesResult = useQueries(queryArray);

  queriesResult.forEach((response) => {
    response.data &&
      (response.data as TrafficDevicesResponse).objects.forEach((obj) => {
        data[obj.id] = obj;
      });
  });
  return {
    data,
    isLoading: queriesResult.some((response) => {
      return response.isLoading;
    }),
  };
};

// Used for counting traffic in both divided traffic type, and combined

export const useQueryCountLinePassingByRange = (
  deviceId: string,
  from: number,
  to: number,
  resolution: string,
  lineId: string,
  direction: string
): {
  data: CountingLineCounterObjectList | undefined;
  refetch: () => void;
  isLoading: boolean;
  remove: () => void;
} => {
  const { data, refetch, isLoading, remove } = useQuery(
    [
      "trafficCountingLine",
      deviceId,
      lineId,
      "from:" + format(from, "yyyy-MM-dd HH:mm"),
      "to: " + format(to, "yyyy-MM-dd HH:mm"),
      resolution,
      direction,
    ],
    async () => {
      const api = await authorizedTrafficCounterApi();
      return (
        await api.getCountLinePassing(
          deviceId,
          lineId,
          direction,
          Math.floor(to / 1000),
          Math.floor(from / 1000),
          resolution
        )
      ).data;
    },
    { retry: 1, refetchInterval: 60000, keepPreviousData: true }
  );

  return { data, refetch, isLoading, remove };
};

// Query for table events

export const useTrafficEventByDeviceDateLine = (
  deviceId: string,
  lineId: string,
  direction: string,
  date: string
): {
  data: EventObjectList | undefined;
  refetch: () => void;
  isLoading: boolean;
} => {
  const { data, refetch, isLoading } = useQuery(
    ["trafficDataLineReords " + deviceId, deviceId, date, lineId, direction],
    async () => {
      const api = await authorizedTrafficCounterApi();
      return (
        await api.getTrafficEventByDeviceDateLine(
          deviceId,
          date,
          lineId,
          direction
        )
      ).data;
    },
    { retry: 1, refetchInterval: 60000 }
  );

  return { data, refetch, isLoading };
};

type TrafficFlow = "Congestion" | "Slow" | "Normal" | "Fast" | "No Passings";

export default function summerizedData(
  interval: number,
  data: { time: number; overAllFlow: TrafficFlow }[]
) {
  const timeSortedData = data.sort((a, b) => {
    return a.time - b.time;
  });

  let startingTime = timeSortedData[0].time;

  const worstOverTime: { time: number; state: string }[] = [];

  while (startingTime < timeSortedData[timeSortedData.length - 1].time) {
    const endingTime = startingTime + interval * 60 * 1000;
    const element = { time: startingTime, state: "Normal" };
    const objectsInTimeBlock = timeSortedData.filter(
      // eslint-disable-next-line
      (el) => el.time > startingTime && el.time <= endingTime
    );

    console.log("objectsInTimeBlock", objectsInTimeBlock);

    objectsInTimeBlock.forEach((object) => {
      if (object.overAllFlow === "Congestion") {
        element.state = "Congestion";
      }

      if (object.overAllFlow === "Slow" && element.state !== "Congestion") {
        element.state = "Slow";
      }
    });
    startingTime = endingTime;
    worstOverTime.push(element);
  }

  console.log("worstOverTime", worstOverTime);

  return worstOverTime;
}

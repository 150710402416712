import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import Box from "@mui/material/Box";
import LineChart from "@smartinspection/ameta-frontend-components/charts/LineChart";
import Button from "@smartinspection/ameta-frontend-components/controls/Button";
import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";
import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";
import { format, parseISO } from "date-fns";
import React from "react";
import { useNavigate } from "react-router-dom";

import { MarkerDefinition } from "components/map/mapMarkers/DeviceMarkers";

import {
  generateFloatingWindow,
  Step,
} from "@smartinspection/ameta-frontend-components/utils/chartUtils";
import {
  Resolution,
  TrafficFlowObject,
  DeviceInfo,
  Zone,
} from "services/trafficCounterApi";
import {
  useQueryTrafficFlowRange,
  useQueryTrafficDeviceInfoByDeviceId,
} from "services/trafficCounterQueryHooks";
import { movingAverage } from "utils/movingAverage";

export default function ZoneDrawerContent({
  marker,
  deviceInfo,
}: {
  marker: MarkerDefinition;
  deviceInfo: DeviceInfo;
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const installationDate = format(
    parseISO(deviceInfo.installationDateTime),
    "dd-MM-yyyy"
  );

  const sensorId = deviceInfo.id;
  const zonePairId = marker.markerId;

  const graphOption = {
    chartConfig: generateFloatingWindow(Step.Minute, 30, 1),
    resolution: Resolution.Minute,
  };

  const counterQuery = useQueryTrafficFlowRange(
    sensorId,
    graphOption.chartConfig.start - 100000,
    graphOption.chartConfig.end,
    graphOption.resolution,
    zonePairId ? zonePairId : "0"
  );

  const selectedZone: Zone | undefined = deviceInfo.zonePairsList?.find(
    (el) => {
      return el.zonePairId === marker.markerId;
    }
  );

  const trafficCounter = counterQuery.data?.objects;

  const multiplyTimeWith1000 = (counter: TrafficFlowObject[]) => {
    return counter?.map((event) => {
      return { ...event, time: event.time * 1000 };
    });
  };
  const deviceInformation = useQueryTrafficDeviceInfoByDeviceId(sensorId).data;

  trafficCounter && movingAverage(trafficCounter, "avgSpeed", 3, "ma");

  if (!selectedZone) {
    return (
      <CenterContainer>
        <Text>Kunne ikke finne tellelinje</Text>
      </CenterContainer>
    );
  }

  let cardContent: React.ReactNode = null;
  const referenceLineProps =
    deviceInformation?.zonePairsList &&
    deviceInformation?.zonePairsList.flatMap((zone) => {
      return [
        {
          value: zone.speedForCongestion,
          axis: "y" as "x" | "y",
          color: theme.palette.error.main,
          label: "Kø",
        },
      ];
    });

  if (counterQuery.isLoading) {
    cardContent = (
      <CenterContainer>
        <CircularProgress size={150} />
      </CenterContainer>
    );
  } else {
    cardContent = (
      <Box sx={{ height: 400, width: "100%" }}>
        <Text variant="h5" sx={{ p: 2, fontWeight: 600 }}>
          Trafikkflyt siste 30 minutter
        </Text>
        {trafficCounter && trafficCounter.length > 0 ? (
          <LineChart
            data={multiplyTimeWith1000(trafficCounter) as any}
            yAxisLabel="Flyt"
            margin={{ left: 0, top: 0, right: 15, bottom: 15 }}
            dataProps={[
              {
                fieldName: "ma",
                name: "Trafikkflyt",
                color: theme.palette.secondary.main,
              },
            ]}
            referenceLineProps={referenceLineProps}
            timeWindow={generateFloatingWindow(Step.Minute, 30, 5)}
          />
        ) : (
          <CenterContainer>
            <Text variant="h5">Ingen data</Text>
          </CenterContainer>
        )}
      </Box>
    );
  }

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          p: 2,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text sx={{ fontWeight: 600 }} variant="h4">
          {deviceInfo.deviceName}
        </Text>

        <Button
          type="info"
          onClick={() => {
            navigate("/dashboard/trafficflow");
          }}
          endIcon={<DirectionsCarIcon />}
        >
          Gå til detaljert visning
        </Button>
      </Box>

      <Box
        sx={{
          px: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ p: 2, width: "50%" }}>
          <Text sx={{ fontWeight: 600 }} variant="body1">
            Kamera:
          </Text>
          <Text variant="body1" sx={{ color: theme.palette.text.secondary }}>
            {deviceInfo.location}
          </Text>
        </Box>

        <Box sx={{ p: 2, width: "50%" }}>
          <Text sx={{ fontWeight: 600 }} variant="body1">
            Retning:
          </Text>
          <Text variant="body1" sx={{ color: theme.palette.text.secondary }}>
            {selectedZone.laneNumbers?.[0] &&
            selectedZone.laneNumbers[0] % 2 === 0
              ? "Mot metreringsretning"
              : "Med metreringsretning"}
          </Text>
        </Box>

        <Box sx={{ p: 2, width: "50%" }}>
          <Text sx={{ fontWeight: 600 }} variant="body1">
            Vegsystemreferanse:
          </Text>
          <Text variant="body1" sx={{ color: theme.palette.text.secondary }}>
            {selectedZone.referenceId}
          </Text>
        </Box>

        <Box sx={{ p: 2, width: "50%" }}>
          <Text sx={{ fontWeight: 600 }} variant="body1">
            Kjørefeltsnummer:
          </Text>
          <Text variant="body1" sx={{ color: theme.palette.text.secondary }}>
            {selectedZone.laneNumbers?.map((lane) => String(lane)).join(", ")}
          </Text>
        </Box>

        <Box sx={{ p: 2, width: "50%" }}>
          <Text sx={{ fontWeight: 600 }} variant="body1">
            Installasjonsdato:
          </Text>
          <Text variant="body1" sx={{ color: theme.palette.text.secondary }}>
            {installationDate}
          </Text>
        </Box>

        <Box sx={{ p: 2, width: "50%" }}>
          <Text sx={{ fontWeight: 600 }} variant="body1">
            Status:
          </Text>
          <Text
            variant="body1"
            sx={{
              fontWeight: 600,
              color:
                marker.status === "alarm"
                  ? theme.palette.error.main
                  : marker.status === "warning"
                  ? theme.palette.warning.main
                  : marker.status === "unknown"
                  ? theme.palette.text.secondary
                  : theme.palette.success.main,
            }}
          >
            {marker.status === "alarm"
              ? "Alarmerende"
              : marker.status === "warning"
              ? "Varslende"
              : marker.status === "unknown"
              ? "Ukjent"
              : "God"}
          </Text>
        </Box>
      </Box>
      <Box sx={{ height: "100%" }}>{cardContent}</Box>
    </Box>
  );
}

import { ma } from "moving-averages";

export function movingAverage(
  arr,
  originalFieldName: string,
  windowSize: number,
  finalFieldName: string
) {
  const original = arr.map((event) => event[originalFieldName]);

  const average: number[] = ma(original, windowSize);

  return arr.forEach((event, index) => {
    event[finalFieldName] = average[index];
  });
}

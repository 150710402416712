import EventIcon from "@mui/icons-material/Event";
import Box from "@mui/material/Box";
import Button from "@smartinspection/ameta-frontend-components/controls/Button";
import { useState, useEffect } from "react";

import useQueryParams, {
  insertUrlParam,
} from "@smartinspection/ameta-frontend-components/utils/useQueryParams";

export enum TimePickerOption {
  none = "none",
  minutes30 = "minutes30",
  hours6 = "hours6",
  days1 = "days1",
  days7 = "days7",
  days30 = "days30",
  custom = "custom",
}

export default function ChartTimePicker({
  onChange,
}: {
  onChange: (pickerOption: TimePickerOption) => void;
}) {
  const [selected, setSelected] = useState(TimePickerOption.none);

  const rangeQP = useQueryParams("range") as string;

  useEffect(() => {
    if (selected !== TimePickerOption.none) {
      onChange(selected);
    }
  }, [selected, onChange]);

  useEffect(() => {
    if (rangeQP && rangeQP in TimePickerOption) {
      setSelected(rangeQP as TimePickerOption);
    }
  }, [rangeQP]);

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <Button
        colored={selected === TimePickerOption.minutes30}
        type="info"
        onClick={() => {
          setSelected(TimePickerOption.minutes30);
          insertUrlParam("range", TimePickerOption.minutes30);
        }}
      >
        30 min
      </Button>
      <Button
        colored={selected === TimePickerOption.hours6}
        type="info"
        onClick={() => {
          setSelected(TimePickerOption.hours6);
          insertUrlParam("range", TimePickerOption.hours6);
        }}
      >
        6 timer
      </Button>
      <Button
        colored={selected === TimePickerOption.days1}
        type="info"
        onClick={() => {
          setSelected(TimePickerOption.days1);
          insertUrlParam("range", TimePickerOption.days1);
        }}
      >
        1 dag
      </Button>
      <Button
        colored={selected === TimePickerOption.days7}
        type="info"
        onClick={() => {
          setSelected(TimePickerOption.days7);
          insertUrlParam("range", TimePickerOption.days7);
        }}
      >
        7 dager
      </Button>
      <Button
        colored={selected === TimePickerOption.days30}
        type="info"
        onClick={() => {
          setSelected(TimePickerOption.days30);
          insertUrlParam("range", TimePickerOption.days30);
        }}
      >
        30 dager
      </Button>
      <Button
        type="info"
        onClick={() => {
          setSelected(TimePickerOption.none);
          insertUrlParam("range", undefined);
          onChange(TimePickerOption.custom);
        }}
      >
        <EventIcon />
      </Button>
    </Box>
  );
}

import { Auth } from "aws-amplify";
import { useQuery } from "react-query";

export const useVideoStreamUrl = (
  streamId: string
): {
  data: { message: string; url: string } | undefined;
  refetch: () => void;
} => {
  const { data, refetch } = useQuery(["videoStreamUrl", streamId], async () => {
    const currentUserSession = await Auth.currentSession();
    const idToken = currentUserSession.getIdToken().getJwtToken();

    const headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("Authorization", idToken);

    return fetch(
      process.env.REACT_APP_API_ENDPOINT + "/video/stream/" + streamId,
      {
        method: "GET",
        headers: headers,
      }
    ).then((res) => res.json());
  });

  return { data, refetch };
};

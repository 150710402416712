import React from "react";

import { format } from "date-fns";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import Card from "@smartinspection/ameta-frontend-components/display/Card";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";
import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";
import {
  ValueIndicator,
  HorizontalSplit,
} from "@smartinspection/ameta-frontend-components";
import {
  generateFloatingWindow,
  Step,
} from "@smartinspection/ameta-frontend-components/utils/chartUtils";

import TrafficFlowStatus from "components/mainView/TrafficFlowStatus";
import { Resolution } from "services/trafficCounterApi";
import {
  useQueryTrafficFlowRange,
  useQueryCountLinePassingByRange,
} from "services/trafficCounterQueryHooks";
import VideoPlayer from "components/camera/VideoPlayer";

import BottomOverviewPassings from "components/mainView/BottomOverviewPassings";
import BottomOverviewFlow from "components/mainView/BottomOverviewFlow";

export default function OverviewCard({
  cardLabel,
  deviceId,
  streamId,
  lineId,
  lineDirection,
  zoneId,
}: {
  cardLabel: string;
  deviceId: string;
  streamId: string;
  lineId: string;
  lineDirection: string;
  zoneId?: string;
}) {
  const chartWindow = generateFloatingWindow(Step.Hour, 24, 2);

  const flowQuery5minutes = useQueryTrafficFlowRange(
    deviceId,
    new Date().setMinutes(new Date().getMinutes() - 5),
    Date.now(),
    Resolution.Minute,
    zoneId ? zoneId : "",
    !zoneId
  );

  const flow5minutes = flowQuery5minutes?.data?.objects;
  const lastFlowRecord = flow5minutes && flow5minutes[flow5minutes.length - 1];

  const passingsQuery24Hours = useQueryCountLinePassingByRange(
    deviceId,
    chartWindow.start,
    chartWindow.end,
    "hour",
    lineId,
    lineDirection
  );
  const passings24hours = passingsQuery24Hours.data;

  const passingsQuery7Days = useQueryCountLinePassingByRange(
    deviceId,
    new Date().setDate(new Date().getDate() - 7),
    new Date().setDate(new Date().getDate() - 1),
    "day",
    lineId,
    "in"
  );

  const passings7Days = passingsQuery7Days.data;
  let totalPassings7Days = 0;
  passings7Days &&
    passings7Days.objects.forEach((day) => {
      totalPassings7Days += day.total;
    });

  const passingsQueryPrev7Days = useQueryCountLinePassingByRange(
    deviceId,
    new Date().setDate(new Date().getDate() - 14),
    new Date().setDate(new Date().getDate() - 8),
    "day",
    lineId,
    "in"
  );
  const passingsPrev7Days = passingsQueryPrev7Days.data;

  let totalPassingsPrev7Days = 0;
  passingsPrev7Days &&
    passingsPrev7Days.objects.forEach((day) => {
      totalPassingsPrev7Days += day.total;
    });

  let cardContent: React.ReactNode = null;

  if (
    passingsQuery24Hours.isLoading ||
    passingsQuery7Days.isLoading ||
    passingsQueryPrev7Days.isLoading
  ) {
    cardContent = (
      <CenterContainer>
        <CircularProgress size={150} />
      </CenterContainer>
    );
  } else if (!passings24hours) {
    <CenterContainer>
      <Text variant="h5">Ingen data</Text>
    </CenterContainer>;
  } else {
    cardContent = (
      <HorizontalSplit
        divider
        top={
          <Box sx={{ py: 2, height: "100%", width: "100%", display: "flex" }}>
            <Box
              sx={{
                position: "relative",
                height: "100%",
                flex: 2,
                p: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <VideoPlayer streamName={streamId} />
            </Box>

            <Box
              sx={{
                flex: 1,
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  pt: 1,
                  flex: 1,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text align="center" variant="h5">
                  Trafikkstatus
                </Text>
                <Text align="center" variant="body2">
                  {lastFlowRecord
                    ? "Oppdatert: " +
                      format(new Date(lastFlowRecord.time * 1000), "HH:mm")
                    : ""}
                </Text>
                <TrafficFlowStatus status={lastFlowRecord?.overAllFlow} />
                {lastFlowRecord && (
                  <Text sx={{ pb: 1 }} align="center" variant="body2">
                    ( 2 minutter etter live video )
                  </Text>
                )}
              </Box>
              <Divider sx={{ width: "90%" }} />
              <Box sx={{ flex: 1, width: "100%" }}>
                <ValueIndicator
                  type="flat"
                  previous={{
                    value: Math.floor(totalPassingsPrev7Days / 7),
                    comparison: "percentage",
                    isIncreaseGood: "yes",
                  }}
                  label="Daglige passeringer, snitt siste 7 dager"
                  value={Math.floor(totalPassings7Days / 7)}
                  compact
                />
              </Box>
            </Box>
          </Box>
        }
        bottom={
          zoneId ? (
            <BottomOverviewFlow deviceId={deviceId} zoneId={zoneId} />
          ) : (
            <BottomOverviewPassings
              deviceId={deviceId}
              lineId={lineId}
              lineDirection={lineDirection}
            />
          )
        }
      />
    );
  }

  return (
    <Card faIcon="chart-bar" title={cardLabel} type="drawer">
      {cardContent}
    </Card>
  );
}

import Box from "@mui/material/Box";
import GridLayout from "@smartinspection/ameta-frontend-components/layout/GridLayout";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";
import React, { useState } from "react";

import TopView from "components/TopView";
import TrafficCongestionCard from "components/congestion/TrafficCongestionCard";

import { deviceIdNameMap } from "config/deviceIdNameMap";
import { useSelector } from "react-redux";
import { TStore } from "redux/store";

function ZoneDetails() {
  const globalState = useSelector((state: TStore) => state.global);

  const [sensorId, setSensorId] = useState(
    "2b5990bd-1949-454d-9372-c1b891522cb7"
  );

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TopView
        title="Trafikkflyt for"
        headerContent={
          <Box sx={{ px: 1 }}>
            <OptionsPicker
              label=""
              value={sensorId}
              optionsList={deviceIdNameMap}
              onChange={setSensorId}
            />
          </Box>
        }
      />
      <GridLayout
        onLayoutChange={(layout) => {}}
        editable={globalState.layoutEditable}
        layout={[
          {
            i: "1",
            h: 30,
            w: 12,
            x: 0,
            y: 0,
          },
        ]}
      >
        <TrafficCongestionCard
          key="1"
          zonePairId={
            "ba6aae21-1c60-4405-b800-c06a80fc20fc|4d783942-0800-4ff8-a74f-bd0a19f3d505"
          }
          sensorId={sensorId}
        />
      </GridLayout>
    </Box>
  );
}

export default ZoneDetails;

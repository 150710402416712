import React from "react";

import Box from "@mui/material/Box";

import TopView from "components/TopView";

import MapView from "components/map/MapView";

export default function Map() {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TopView title="Kartvisning" />
      <MapView />
    </Box>
  );
}

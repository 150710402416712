import Box from "@mui/material/Box";
import Card from "@smartinspection/ameta-frontend-components/display/Card";
import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";
import React, { useState } from "react";

import ChartTimePicker, {
  TimePickerOption,
} from "components/common/ChartTimePicker";
import DateRangePicker from "components/common/DateRangePicker";
import CongestionChart from "components/congestion/CongestionChart";

import {
  generateStaticWindow,
  generateFloatingWindow,
  Step,
} from "@smartinspection/ameta-frontend-components/utils/chartUtils";
import { TrafficFlowObject } from "services/trafficCounterApi";
import { useQueryTrafficFlowRange } from "services/trafficCounterQueryHooks";

function TrafficCongestionCard({
  sensorId,
  zonePairId,
}: {
  sensorId: string;
  zonePairId: string;
}) {
  const resolutionPicker = (from: Date, to: Date) => {
    const fromNumber = +from;
    const toNumber = +to;
    const dateDiff = Math.abs(toNumber - fromNumber); // in ms
    const dateDiffMinute = dateDiff / 60000;
    const dateDiffHour = dateDiffMinute / 60;
    const dateDiffDay = dateDiffHour / 24;
    const maxRecords = 732;

    if (dateDiffMinute < maxRecords) {
      return Step.Minute;
    } else if (dateDiffHour < maxRecords) {
      return Step.Hour;
    } else if (dateDiffDay < maxRecords) {
      return Step.Day;
    } else {
      return Step.Day; // return error
    }
  };

  const today = new Date();
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));

  const [rangeDialogOpen, setRangeDialogOpen] = useState(false);

  const multiplyTimeWith1000 = (counter: TrafficFlowObject[]) => {
    return counter?.map((event) => {
      return { ...event, time: event.time * 1000 };
    });
  };

  const graphOptions = {
    minutes30: {
      chartConfig: generateFloatingWindow(Step.Minute, 60, 5),
      resolution: Step.Minute,
      interval: 5,
    },
    hours6: {
      chartConfig: generateFloatingWindow(Step.Hour, 6, 1),
      resolution: Step.Minute,
      interval: 5,
    },
    days1: {
      chartConfig: generateFloatingWindow(Step.Hour, 24, 1),
      resolution: Step.Minute,
      interval: 5,
    },
    days7: {
      chartConfig: generateFloatingWindow(Step.Day, 7, 1),
      resolution: Step.Hour,
      interval: 60,
    },
    days30: {
      chartConfig: generateFloatingWindow(Step.Day, 30, 1),
      resolution: Step.Hour,
      interval: 60,
    },
    custom: {
      chartConfig: generateStaticWindow(
        resolutionPicker(yesterday, today) as any,
        yesterday.getTime(),
        today.getTime(),
        1
      ),
      resolution: resolutionPicker(yesterday, today),
      interval: 60,
    },
  };

  const [graphOption, setGraphOption] = useState(graphOptions.days1);
  const convertResolutionDict = {
    Minute: "minute",
    Hour: "hour",
    Day: "day",
  };

  const counterQuery = useQueryTrafficFlowRange(
    sensorId,
    graphOption.chartConfig.start,
    graphOption.chartConfig.end,
    convertResolutionDict[graphOption.resolution],
    zonePairId
  );
  const trafficCounter = counterQuery.data?.objects;
  let cardContent: React.ReactNode = null;

  if (counterQuery.isLoading) {
    cardContent = (
      <CenterContainer>
        <CircularProgress size={150} />
      </CenterContainer>
    );
  } else {
    if (trafficCounter && trafficCounter.length > 0) {
      cardContent = (
        <CongestionChart
          data={multiplyTimeWith1000(trafficCounter) as any} // got numbers in here
          timeWindow={graphOption.chartConfig}
          interval={graphOption.interval}
          margin={{ left: 60, top: 15, right: 10, bottom: 20 }}
        />
      );
    } else {
      cardContent = (
        <CenterContainer>
          <Text variant="h5">Ingen data</Text>
        </CenterContainer>
      );
    }
  }

  return (
    <Card
      faIcon="chart-bar"
      title="Trafikktilstand siste 24 timer"
      type="drawer"
      rightHeaderComponent={
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            pr: 2,
          }}
        >
          <ChartTimePicker
            onChange={(pickerOption) => {
              if (pickerOption === TimePickerOption.custom) {
                setRangeDialogOpen(true);
              } else {
                setGraphOption(graphOptions[pickerOption]);
              }
            }}
          />
        </Box>
      }
    >
      {cardContent}
      <DateRangePicker
        open={rangeDialogOpen}
        onRangeSet={(start, stop, stepInterval) => {
          setRangeDialogOpen(false);
          setGraphOption({
            chartConfig: generateStaticWindow(
              resolutionPicker(start, stop) as any,
              start.getTime(),
              stop.getTime(),
              stepInterval
            ),
            interval: 60,
            resolution: resolutionPicker(start, stop),
          });
        }}
        onClose={() => {
          setRangeDialogOpen(false);
        }}
      />
    </Card>
  );
}

export default TrafficCongestionCard;

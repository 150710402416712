import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import NavBar from "components/NavBar";
import Camera from "pages/Camera";
import LineDetails from "pages/LineDetails";
import MainDash from "pages/MainDash";
import Map from "pages/Map";
import Settings from "pages/Settings";
import TableDetails from "pages/TableDetails";
import ZoneDetails from "pages/ZoneDetails";

import useQueryParams from "@smartinspection/ameta-frontend-components/utils/useQueryParams";
import { useDispatch } from "react-redux";
import { hideMenus } from "redux/global";
import isUserLoggedIn from "utils/isUserLoggedIn";

export default function Main() {
  const user = isUserLoggedIn();
  const navigate = useNavigate();

  useEffect(() => {
    if (user === "nouser") {
      navigate("/");
    }
  });

  const dispatch = useDispatch();
  const fullscreen = useQueryParams("fullscreen");

  useEffect(() => {
    if (fullscreen) {
      dispatch(hideMenus());
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 300);
    }
  }, [dispatch, fullscreen]);

  return (
    <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }}>
      <NavBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Routes>
          <Route path="/" element={<MainDash />} />
          <Route path="/map" element={<Map />} />
          <Route path="/table" element={<TableDetails />} />
          <Route path="/linecount" element={<LineDetails />} />
          <Route path="/trafficflow" element={<ZoneDetails />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/camera" element={<Camera />} />
        </Routes>
      </Box>
    </Box>
  );
}

import React from "react";

import Box from "@mui/material/Box";

import TopView from "components/TopView";

import TableView from "components/table/TableView";

export default function Map() {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TopView title="Tabellvisning" />
      <TableView />
    </Box>
  );
}

import Box from "@mui/material/Box";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import GeoJson from "@smartinspection/ameta-frontend-components/map/GeoJson";
import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";
import { AwesomeMarkers } from "leaflet";

import { MarkerDefinition } from "components/map/mapMarkers/DeviceMarkers";

export default function Marker({
  markerDefinition,
  onMarkerClick,
}: {
  markerDefinition: MarkerDefinition;
  onMarkerClick: (marker: MarkerDefinition) => void;
}) {
  const theme = useTheme();

  let markerColor: AwesomeMarkers.AwesomeMarkersIconOptions["markerColor"] =
    "cadetblue";
  let strokeColor: React.CSSProperties["color"] = theme.palette.grey[300];
  let icon: string = "camera";

  switch (markerDefinition.type) {
    case "Camera":
      icon = "video";
      break;
    case "Line":
      icon = "road";
      break;
    case "Zone":
      icon = "camera";
      break;
  }

  switch (markerDefinition.status) {
    case "ok":
      markerColor = "green";
      strokeColor = theme.palette.success.main;
      break;

    case "warning":
      markerColor = "orange";
      strokeColor = theme.palette.warning.main;
      break;

    case "alarm":
      markerColor = "red";
      strokeColor = theme.palette.error.main;
      break;

    case "unknown":
      markerColor = "cadetblue";
      strokeColor = theme.palette.grey[300];
      break;
  }

  return (
    <GeoJson
      key={
        markerDefinition.markerId +
        markerDefinition.status +
        markerDefinition.lineDirection
      }
      marker={{
        color: markerColor,
        icon,
      }}
      pathOptions={{ color: strokeColor, weight: 5 }}
      onClick={() => onMarkerClick(markerDefinition)}
      data={markerDefinition.geojson}
      tooltip={
        <Box
          style={{
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          <Text variant="body1" style={{ ...theme.typography.body1 }}>
            {markerDefinition.name}
          </Text>
        </Box>
      }
    />
  );
}

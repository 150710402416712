import Box from "@mui/material/Box";
import React from "react";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import LineChart from "@smartinspection/ameta-frontend-components/charts/LineChart";
import Helper from "components/common/Helper";
import {
  generateFloatingWindow,
  Step,
} from "@smartinspection/ameta-frontend-components/utils/chartUtils";
import { useQueryCountLinePassingByRange } from "services/trafficCounterQueryHooks";

import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";
import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";

import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";

export default function BottomOverviewPassings({
  deviceId,
  lineId,
  lineDirection,
}: {
  deviceId: string;
  lineId: string;
  lineDirection: string;
}) {
  const theme = useTheme();

  const chartWindow = generateFloatingWindow(Step.Hour, 24, 2);

  const passingsQuery24Hours = useQueryCountLinePassingByRange(
    deviceId,
    chartWindow.start,
    chartWindow.end,
    "hour",
    lineId,
    lineDirection
  );

  const passings24hours = passingsQuery24Hours.data;

  if (passingsQuery24Hours.isLoading) {
    return (
      <CenterContainer>
        <CircularProgress size={150} />
      </CenterContainer>
    );
  }

  if (!passings24hours) {
    return (
      <CenterContainer>
        <Text variant="h3">Kunne ikke hente data</Text>
      </CenterContainer>
    );
  }

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ py: 1, display: "flex", alignItems: "center", gap: 4 }}>
        <Text sx={{ mt: 1 }} variant="h6">
          Antall passeringer all trafikk, siste 24 timer
        </Text>
        <Helper placement="right">
          <Text>
            Denne kurven viser trafikkpasseringer fra alle kjøyetøy per time.
            Tallet som vises på hvert punkt er alle passeringer fra timen før og
            frem til den timen man ser på.
          </Text>
        </Helper>
      </Box>
      <LineChart
        yAxisLabel="Passeringer"
        margin={{ top: 0, bottom: 15, right: 10, left: 10 }}
        data={passings24hours.objects.map((el) => ({
          time: el.time * 1000,
          total: el.total,
        }))}
        dataProps={[
          {
            fieldName: "total",
            name: "Passeringer i timen, alle kjøretøy",
            color: theme.palette.secondary.main,
          },
        ]}
        timeWindow={chartWindow}
      />
    </Box>
  );
}

import Box from "@mui/material/Box";

import React from "react";
import videojs from "video.js";

import VideoJS from "./VideoJS";
import { useVideoStreamUrl } from "services/videoQueryHooks";

interface IParam {
  streamName: string;
}

export default function VideoPlayer({ streamName }: IParam) {
  const urlQuery = useVideoStreamUrl(streamName);
  const streamingUrl = urlQuery.data?.url;

  const playerRef = React.useRef(null);

  if (!streamingUrl) {
    return (
      <Box
        sx={{
          position: "relative",
          height: "100%",
          flex: 2,
          p: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Laster video...
      </Box>
    );
  }

  const videoJsOptions: videojs.PlayerOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    muted: true,
    liveui: true,
    sources: [
      {
        src: streamingUrl,
        type: "application/x-mpegURL",
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on("dispose", () => {
      console.log("player will dispose");
    });

    player.tech_.on("retryplaylist", () => {
      console.log("player retryplaylist");
      urlQuery.refetch();
    });
  };

  return (
    <Box sx={{ width: "78%", height: "100%" }}>
      <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
    </Box>
  );
}

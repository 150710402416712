import Box from "@mui/material/Box";
import * as materialColors from "@mui/material/colors";
import LineChart from "@smartinspection/ameta-frontend-components/charts/LineChart";
import Card from "@smartinspection/ameta-frontend-components/display/Card";
import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";
import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";
import React, { useState, useEffect } from "react";

import ChartTimePicker, {
  TimePickerOption,
} from "components/common/ChartTimePicker";
import DateRangePicker from "components/common/DateRangePicker";

import {
  generateStaticWindow,
  generateFloatingWindow,
  Step,
} from "@smartinspection/ameta-frontend-components/utils/chartUtils";
import useQueryParams from "@smartinspection/ameta-frontend-components/utils/useQueryParams";
import { CountingLineCounterObject } from "services/trafficCounterApi";
import { useQueryCountLinePassingByRange } from "services/trafficCounterQueryHooks";

function LineDetailsView({
  lineIdReference,
}: {
  lineIdReference: { lineId: string; deviceId: string; direction: string };
}) {
  const theme = useTheme();

  const resolutionPicker = (from: Date, to: Date) => {
    const fromNumber = +from;
    const toNumber = +to;
    const dateDiff = Math.abs(toNumber - fromNumber); // in ms
    const dateDiffMinute = dateDiff / 60000;
    const dateDiffHour = dateDiffMinute / 60;
    const dateDiffDay = dateDiffHour / 24;
    const maxRecords = 732;

    if (dateDiffMinute < maxRecords) {
      return Step.Minute;
    } else if (dateDiffHour < maxRecords) {
      return Step.Hour;
    } else if (dateDiffDay < maxRecords) {
      return Step.Day;
    } else {
      return Step.Day; // return error
    }
  };

  const today = new Date();
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));

  const graphOptions = {
    minutes30: {
      chartConfig: generateFloatingWindow(Step.Minute, 60, 5),
      resolution: Step.Minute,
    },
    hours6: {
      chartConfig: generateFloatingWindow(Step.Hour, 6, 1),
      resolution: Step.Minute,
    },
    days1: {
      chartConfig: generateFloatingWindow(Step.Hour, 24, 1),
      resolution: Step.Hour,
    },
    days7: {
      chartConfig: generateFloatingWindow(Step.Day, 7, 1),
      resolution: Step.Hour,
    },
    days30: {
      chartConfig: generateFloatingWindow(Step.Hour, 30, 1),
      resolution: Step.Hour,
    },
    custom: {
      chartConfig: generateStaticWindow(
        resolutionPicker(yesterday, today) as any,
        yesterday.getTime(),
        today.getTime(),
        1
      ),
      resolution: resolutionPicker(yesterday, today),
    },
  };

  const [range, setRange] = useState("days1");

  const rangeQP = useQueryParams("range") as string;

  useEffect(() => {
    if (rangeQP && rangeQP !== range) {
      setRange(rangeQP);
    }
  }, [range, rangeQP]);

  const [graphOption, setGraphOption] = useState(
    graphOptions[range] ? graphOptions[range] : graphOptions.days1
  );

  const convertResolutionDict = {
    Minute: "minute",
    Hour: "hour",
    Day: "day",
  };

  const [rangeDialogOpen, setRangeDialogOpen] = useState(false);

  const lineCounterQuery = useQueryCountLinePassingByRange(
    lineIdReference.deviceId,
    graphOption.chartConfig.start,
    graphOption.chartConfig.end,
    convertResolutionDict[graphOption.resolution],
    lineIdReference.lineId,
    lineIdReference.direction
  );

  const trafficCounter = lineCounterQuery.data?.objects;

  const multiplyTimeWith1000 = (counter: CountingLineCounterObject[]) => {
    return counter?.map((event) => {
      return { ...event, time: event.time * 1000 }; // TODO: recharts needs unixtime with ms.
    });
  };

  const refineCounter = (counter: CountingLineCounterObject[]) => {
    return counter?.map((event) => {
      return {
        ...event,
        time: event.time * 1000,
        heavy: event.truck + event.bus,
      }; // TODO: recharts needs unixtime with ms.
    });
  };

  let cardContent: React.ReactNode = null;

  if (lineCounterQuery.isLoading) {
    cardContent = (
      <CenterContainer>
        <CircularProgress size={150} />
      </CenterContainer>
    );
  } else {
    if (trafficCounter) {
      cardContent = (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <LineChart
            data={multiplyTimeWith1000(trafficCounter)}
            yAxisLabel="Passeringer"
            margin={{ left: 10, top: 30, right: 10, bottom: 30 }}
            dataProps={[
              {
                fieldName: "total",
                name: "Passeringer, all trafikk",
                color: theme.palette.secondary.light,
              },
            ]}
            timeWindow={graphOption.chartConfig}
          />
          <LineChart
            stackGraphs
            showAsPercentage
            data={refineCounter(trafficCounter)}
            yAxisLabel="Trafikkfordeling"
            margin={{ left: 25, top: 30, right: 10, bottom: 15 }}
            dataProps={[
              {
                fieldName: "heavy",
                name: "Tungt kjøretøy",

                color: materialColors.blue[500],
              },

              {
                fieldName: "car",
                name: "Personbil",
                color: theme.palette.secondary.main,
              },
            ]}
            timeWindow={graphOption.chartConfig}
          />
        </Box>
      );
    } else {
      cardContent = (
        <CenterContainer>
          <Text variant="h5">Ingen data</Text>
        </CenterContainer>
      );
    }
  }

  return (
    <Card
      faIcon="chart-bar"
      title="Passeringer"
      type="drawer"
      rightHeaderComponent={
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            pr: 2,
          }}
        >
          <ChartTimePicker
            onChange={(pickerOption) => {
              if (pickerOption === TimePickerOption.custom) {
                setRangeDialogOpen(true);
              } else {
                setGraphOption(graphOptions[pickerOption]);
              }
            }}
          />
        </Box>
      }
    >
      {cardContent}
      <DateRangePicker
        open={rangeDialogOpen}
        onRangeSet={(start, stop, stepInterval) => {
          setRangeDialogOpen(false);

          setGraphOption({
            chartConfig: generateStaticWindow(
              resolutionPicker(start, stop),
              start.getTime(),
              stop.getTime(),
              stepInterval
            ),
            resolution: resolutionPicker(start, stop),
          });
        }}
        onClose={() => {
          setRangeDialogOpen(false);
        }}
      />
    </Card>
  );
}

export default LineDetailsView;

import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  hashArray: string[];
  centerPoint: { lat: number; lng: number };
  zoomLevel: number;
} = {
  hashArray: [],
  centerPoint: { lat: 58.1464021694255, lng: 7.984258268021254 },
  zoomLevel: 17,
};

export const mapsSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setHashArray: (state, action) => {
      state.hashArray = action.payload;
    },
    setCenterPoint: (state, action) => {
      state.centerPoint = action.payload;
    },
    setZoomLevel: (state, action) => {
      state.zoomLevel = action.payload;
    },
  },
});

export const { setHashArray, setCenterPoint, setZoomLevel } = mapsSlice.actions;

export default mapsSlice.reducer;

import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import CustomizedLineAxisTick from "@smartinspection/ameta-frontend-components/charts/CustomizedLineAxisTick";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";
import React from "react";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
} from "recharts";

import { TimeWindow } from "@smartinspection/ameta-frontend-components/utils/chartUtils";
import summerizedData from "utils/congestionSummerizer";

type TrafficFlow = "Congestion" | "Slow" | "Normal" | "Fast" | "No Passings";

export default function CongestionChart({
  data,
  margin,
  timeWindow,
  interval,
}: {
  data: { time: number; overAllFlow: TrafficFlow }[];
  margin?: { left: number; top: number; right: number; bottom: number };
  timeWindow: TimeWindow;
  interval: number;
}) {
  const theme = useTheme();

  const worstOverTime = summerizedData(interval, data);

  const chartData: {
    time: number;
    congested: number;
    slow: number;
    normal: number;
  }[] = worstOverTime.map((el) => {
    if (el.state === "Congestion") {
      return {
        time: el.time,
        congested: 100,
        slow: 0,
        normal: 0,
      };
    } else if (el.state === "Slow") {
      return {
        time: el.time,
        congested: 0,
        slow: 100,
        normal: 0,
      };
    } else if (el.state === "Normal") {
      return {
        time: el.time,
        congested: 0,
        slow: 0,
        normal: 100,
      };
    } else if (el.state === "Fast") {
      return {
        time: el.time,
        congested: 0,
        slow: 0,
        normal: 100,
      };
    } else if (el.state === "No Passings") {
      return {
        time: el.time,
        congested: 0,
        slow: 0,
        normal: 100,
      };
    } else {
      return {
        time: el.time,
        congested: 0,
        slow: 0,
        normal: 100,
      };
    }
  });

  return (
    <ResponsiveContainer width="100%" height="99%">
      <AreaChart
        margin={margin}
        data={chartData.sort((a, b) => {
          return a.time - b.time;
        })}
      >
        <Legend
          payload={[
            {
              fieldName: "congestion",
              name: "Stans",
              color: theme.palette.error.main,
            },
            {
              fieldName: "slow",
              name: "Saktegående",
              color: theme.palette.warning.main,
            },
            {
              fieldName: "normal",
              name: "Normalt",
              color: theme.palette.success.main,
            },
          ].map((prop) => ({
            id: prop.fieldName,
            type: "circle",
            value: prop.name,
            color: prop.color,
          }))}
          content={(props) => {
            const { payload } = props;

            return (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  py: 2,
                }}
              >
                {payload &&
                  payload.map((entry, index) => (
                    <Box
                      key={index}
                      sx={
                        {
                          display: "flex",
                          alignItems: "center",
                          pr: index !== payload.length - 1 && 2,
                        } as SxProps
                      }
                    >
                      <Box
                        sx={{
                          height: 12,
                          width: 12,
                          borderRadius: "50%",
                          mr: 1,
                          backgroundColor: entry.color ? entry.color : "black",
                        }}
                      />
                      <Text variant="body2">{entry.value}</Text>
                    </Box>
                  ))}
              </Box>
            );
          }}
          align="right"
          verticalAlign="top"
        />
        <CartesianGrid strokeDasharray="9 9" />
        <YAxis hide tickCount={1} />
        <XAxis
          allowDataOverflow
          type="number"
          dataKey="time"
          interval={0}
          domain={timeWindow.domain}
          ticks={timeWindow.tickers}
          tick={<CustomizedLineAxisTick step={timeWindow.step} />}
        />
        <Area
          strokeWidth={0}
          type="stepAfter"
          dataKey="congested"
          stackId="1"
          fillOpacity={1}
          fill={theme.palette.error.main}
        />
        <Area
          strokeWidth={0}
          type="stepAfter"
          dataKey="slow"
          stackId="1"
          fillOpacity={1}
          fill={theme.palette.warning.main}
        />
        <Area
          strokeWidth={0}
          type="stepAfter"
          dataKey="normal"
          stackId="1"
          fillOpacity={1}
          fill={theme.palette.success.main}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

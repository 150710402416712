import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";
import React from "react";

import CameraDrawerContent from "components/map/drawer/CameraDrawerContent";
import LineDrawerContent from "components/map/drawer/LineDrawerContent";
import ZoneDrawerContent from "components/map/drawer/ZoneDrawerContent";
import { MarkerDefinition } from "components/map/mapMarkers/DeviceMarkers";

import { useQueryTrafficDeviceInfoByDeviceId } from "services/trafficCounterQueryHooks";

export default function DrawerContents({
  marker,
}: {
  marker: MarkerDefinition;
}) {
  const deviceInfoQuery = useQueryTrafficDeviceInfoByDeviceId(marker.deviceId);

  if (deviceInfoQuery.isLoading || !marker) {
    return (
      <CenterContainer>
        <CircularProgress size={150} />
      </CenterContainer>
    );
  }

  const deviceInfo = deviceInfoQuery.data;

  if (!deviceInfo) {
    return (
      <CenterContainer>
        <Text>Kunne ikke finne enhet</Text>
      </CenterContainer>
    );
  }

  if (marker.type === "Camera") {
    return <CameraDrawerContent marker={marker} deviceInfo={deviceInfo} />;
  } else if (marker.type === "Line" && marker.lineDirection) {
    return (
      <LineDrawerContent
        marker={marker}
        lineDirection={marker.lineDirection}
        deviceInfo={deviceInfo}
      />
    );
  } else {
    return <ZoneDrawerContent marker={marker} deviceInfo={deviceInfo} />;
  }
}

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCalculator,
  faTrafficLight,
  faRoad,
  faHardHat,
  faCarAlt,
  faBus,
  faTruck,
  faMotorcycle,
  faChartBar,
  faVideo,
  faCircleCheck,
  faTriangleExclamation,
  faCircleQuestion,
  faCircleExclamation,
  faPercent,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faCalculator,
  faTrafficLight,
  faRoad,
  faHardHat,
  faCarAlt,
  faBus,
  faTruck,
  faMotorcycle,
  faChartBar,
  faVideo,
  faCircleCheck,
  faTriangleExclamation,
  faCircleQuestion,
  faCircleExclamation,
  faPercent
);

import Box from "@mui/material/Box";
import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";
import React from "react";

import Helper from "components/common/Helper";
import CongestionChart from "components/congestion/CongestionChart";

import {
  generateFloatingWindow,
  Step,
} from "@smartinspection/ameta-frontend-components/utils/chartUtils";
import { Resolution, TrafficFlowObject } from "services/trafficCounterApi";
import { useQueryTrafficFlowRange } from "services/trafficCounterQueryHooks";

export default function BottomOverviewFlow({
  deviceId,
  zoneId,
}: {
  deviceId: string;
  zoneId: string;
}) {
  const timeWindow = generateFloatingWindow(Step.Hour, 12, 1);

  const counterQuery = useQueryTrafficFlowRange(
    deviceId,
    timeWindow.start,
    timeWindow.end,
    Resolution.Minute,
    zoneId
  );

  const trafficCounter = counterQuery.data?.objects;

  const multiplyTimeWith1000 = (counter: TrafficFlowObject[]) => {
    return counter?.map((event) => {
      return { ...event, time: event.time * 1000 };
    });
  };

  if (counterQuery.isLoading) {
    return (
      <CenterContainer>
        <CircularProgress size={150} />
      </CenterContainer>
    );
  }

  if (!trafficCounter) {
    return (
      <CenterContainer>
        <Text variant="h3">Kunne ikke hente data</Text>
      </CenterContainer>
    );
  }

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ py: 1, display: "flex", alignItems: "center", gap: 4 }}>
        <Text sx={{ mt: 1 }} variant="h6">
          Trafikkflyt, siste 12 timer
        </Text>
        <Helper placement="right">
          <Text>
            Denne kurven viser status på trafikkflyten fra alle kjøyetøy siste
            12 timer. En strek tilsvarer en tidsperiode på minst fem minutter.
          </Text>
        </Helper>
      </Box>
      <CongestionChart
        data={multiplyTimeWith1000(trafficCounter) as any} // got numbers in here
        interval={5}
        timeWindow={timeWindow}
        margin={{ left: 30, top: 0, right: 15, bottom: 15 }}
      />
    </Box>
  );
}
